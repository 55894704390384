import {dayMonthYear} from "../../Common/Utils/DateFormat";
import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import BandeiraSelector from "../Bandeira/Component/BandeiraSelector";
import PendenteSelector from "./Component/PendenteSelector";
import EmbarqueTipoSelector from "./Component/EmbarqueTipoSelector";
import SituacaoSelector from "./Component/SituacaoSelector";
import NavioStatusSelector from "./Component/NavioStatusSelector";

export const NavioListSchema = () => {
  const endpoint = 'navios';

  return {
    getTitle: () => {
      return 'Lista de Navios'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },

        {
          header: "IMO",
          accessorKey: "imo"
        },
        {
          id: "Bandeira.Id",
          header: "Bandeira",
          accessorKey: "bandeira.Id",
          customFilterSelectorElement: BandeiraSelector,
          cell: (item) => item.row.original.bandeira?.sigla
        },
        {
          header: "Pendente",
          accessorKey: "pendente",
          customFilterSelectorElement: PendenteSelector,
          accessorFn: (row) => row.pendente ? "SIM" : "NÃO"
        },
        {
          header: "Tipo",
          accessorKey: "tipo",
          customFilterSelectorElement: EmbarqueTipoSelector,
          accessorFn: (row) => row.tipo ? "EMBARQUE" : "DESEMBARQUE"
        },
        {
          header: "Data de Construção",
          accessorKey: "dataConstrucao",
          enableColumnFilter:false,
          cell: (item) => dayMonthYear(item.row.original.dataConstrucao)
        },
        {
          header: "Situação",
          accessorKey: "situacao",
          customFilterSelectorElement: SituacaoSelector,
          accessorFn: (row) => row.situacao ? "OPERACIONAL" : "NÃO OPERACIONAL"

        },
        {
          header: "Status",
          accessorKey: "status",
          customFilterSelectorElement: NavioStatusSelector,
          accessorFn: (row) => row.situacao ? "ATIVO" : "INATIVO"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


