import {Card} from "react-bootstrap";
import React from "react";
import {formatarCodigoPilha} from "../../Pilha/Component/PilhaFormatter";
import MovimentacaoPilha from "./MovimentacaoPilha";

function EstoquePilhaDetalhado({pilha}) {

    return <Card className="pt-3 px-2">
        {pilha &&
            <Card className="bg-light" key={pilha.id}>
                <Card.Header>
                    {`Pilha: ${formatarCodigoPilha(pilha.codigoPilha.numero, pilha.codigoPilha.ano)} - Material: ${pilha.material?.nome} `}
                </Card.Header>
                <Card.Body>
                    <MovimentacaoPilha pilhaId={pilha.id}/>
                </Card.Body>
            </Card>
        }
    </Card>
}

export default EstoquePilhaDetalhado;