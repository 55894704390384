import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const SENTIDO_FORMACAO = [
  {id: "INICIO_FIM", label: "Início → Fim"},
  {id: "FIM_INICIO", label: "Fim → Início"}
]

const SentidoFormacaoSelector = ({
  patio = null,
  required = false,
  title = "Sentido de formação",
  setValue = SENTIDO_FORMACAO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      items={SENTIDO_FORMACAO}
      setValue={setValue}
      {...props}
  />
}
export default SentidoFormacaoSelector;
