import {Link} from "react-router-dom";
import {CurrentTitle} from "./CurrentTitle";

export const MyNav = () => {

  return (
    <nav className="main-header navbar navbar-expand">
      <ul className="navbar-nav">

        <li className="nav-item">
          <Link className="nav-link" to="" data-widget="pushmenu"
                onClick={e => e.preventDefault()} role="button"><i
            className="fas fa-bars"/></Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="fas fa-home"/></Link>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        {/*<CompanyName/>*/}
        <CurrentTitle/>
      </ul>
      <ul className="navbar-nav ml-auto">
      </ul>
    </nav>)

}
