import BTable from "react-bootstrap/Table";
import React from "react";
import {Row} from "react-bootstrap";
import {dayMonthYear, HourMinute} from "../../../Common/Utils/DateFormat";

export const MEIO_MOVIMENTACAO = [
    {id:"AJUSTE" , label:"Ajuste"},
    {id:"OPERACAO", label:"Operacional"}
]

function MovimentacoesTable({titulo, listaMovimentacoes}) {
    return <>
        <Row className="justify-content-center h5">{titulo}</Row>
        <BTable responsive striped bordered hover size="sm">
            <thead className="thead-dark">
            <tr>
                <th>
                    Data
                </th>
                <th>
                    Hora
                </th>
                <th>
                    Volume
                </th>
                <th>
                    Tipo
                </th>
            </tr>
            </thead>
            <tbody>
            {listaMovimentacoes && listaMovimentacoes.map(x =>
                <tr key={x.id}>
                    <td>
                        {dayMonthYear(x.data)}
                    </td>
                    <td>
                        {HourMinute(x.data)}
                    </td>
                    <td>
                        {x.volume}
                    </td>
                    <td>
                        {MEIO_MOVIMENTACAO.find(a => a.id === x.meioMovimentacao)?.label}
                    </td>
                </tr>
            )}
            </tbody>
        </BTable></>;
}

export default MovimentacoesTable;