import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {formatarCodigoPilha} from "./Component/PilhaFormatter";
import {dayMonthYearAtTime} from "../../Common/Utils/DateFormat";

export const MovimentacaoListSchema = () => {

  const endpoint = 'movimentacoes';

  return {
    getTitle: () => {
      return 'Lista de Pilhas'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Pilha",
          accessorKey: "pilha",
          accessorFn: (row) => formatarCodigoPilha( row.pilha?.codigoPilha?.numero, row.pilha?.codigoPilha?.ano)
        },
        {
          header: "Tipo de movimentação",
          accessorKey: "tipoMovimentacao",
          accessorFn: (row) => row.tipoMovimentacao === "DEPOSITO" ? "Depósito" : "Retirada"
        },
        {
          header: "Data da movimentação",
          accessorKey: "data",
          dateRange: true,
          accessorFn: (row) => dayMonthYearAtTime(row.data)
        },
        {
          header: "Volume (t)",
          accessorKey: "volume"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


