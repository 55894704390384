import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {dayMonthYear} from "../../Common/Utils/DateFormat";

export const CargaNavioPTCListSchema = () => {
  const endpoint = 'carga-agendamento';

  return {
    getTitle: () => {
      return 'Lista de agendamentos'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "dataCTRLaydays",
          accessorKey: "dataCTRLaydays",
          cell: (item) => dayMonthYear(item.row.original.dataCTRLaydays)
        },

        {
          header: "dataLiberacaoFrete",
          accessorKey: "dataLiberacaoFrete",
          cell: (item) => dayMonthYear(item.row.original.dataLiberacaoFrete)
        },
        {
          header: "FILA",
          accessorKey: "fila"
        },
        {
          header: "dataCTRNomeacao",
          accessorKey: "dataCTRNomeacao",
          cell: (item) => dayMonthYear(item.row.original.dataCTRNomeacao)
        },
        {
          header: "pesoP",
          accessorKey: "pesoP"
        },
        {
          header: "pesoR",
          accessorKey: "pesoR"
        },
        {
          header: "porto",
          accessorKey: "porto.nome"
        },
        {
          header: "material",
          accessorKey: "material.nome"
        },
        {
          header: "fornecedor",
          accessorKey: "fornecedor.nome"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


