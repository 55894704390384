import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {BandeiraListSchema} from "./BandeiraListSchema";
import BandeiraFormSchema from "./BandeiraFormSchema";

export const BandeiraModule = () => {


  const BANDEIRA_LIST = MenuItem({
    name: 'Bandeira',
    path: 'bandeira',
    icon: 'fa fa-flag',
    canCreateRoute: false
  });

  addListSchema('bandeira', BandeiraListSchema);
  addFormSchema('bandeira', BandeiraFormSchema);

  CADASTRO_MENU.children.push(BANDEIRA_LIST);

  return [];

}
