import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {PlantaListSchema} from "./PlantaListSchema";
import PlantaFormSchema from "./PlantaFormSchema";

export const PlantaModule = () => {


  const PLANTA_LIST = MenuItem({
    name: 'Planta',
    path: 'planta',
    icon: 'fa fa-warehouse',
    canCreateRoute: false
  });

  addListSchema('planta', PlantaListSchema);
  addFormSchema('planta', PlantaFormSchema);

  CADASTRO_MENU.children.push(PLANTA_LIST);

  return [];

}
