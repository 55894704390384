import 'admin-lte/dist/js/adminlte'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars'

import {Link} from "react-router-dom";
import $ from 'jquery';
import {Logo} from "../Logo/Logo";
import {CurrentUser} from "./CurrentUserNav";
import {SidebarItem} from "./SidebarItem";
import {useEffect} from "react";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

export const Sidebar = () => {


  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);


  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={styles.sidebarContainer}>

      <Link to="/" className="brand-link text-center">
        <Logo className="elevation-3" sidebar={true}/>
      </Link>

      <CurrentUser/>

      <nav>
        <ul className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview" data-accordion="false"
            role="menu">
          <SidebarItem/>
        </ul>
      </nav>

    </aside>
  )
}

const styles = {
  sidebarContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'fixed',
    height: '100vh'
  }
}
