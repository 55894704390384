import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {AjusteEstoqueListSchema} from "./AjusteEstoqueListSchema";
import AjusteEstoqueFormSchema from "./AjusteEstoqueFormSchema";

export const AjusteEstoqueModule = () => {


  const AJUSTE_ESTOQUE_LIST = MenuItem({
    name: 'Ajuste de estoque',
    path: 'ajustes-estoque',
    icon: 'fa fa-sliders-h',
    canCreateRoute: false
  });

  addListSchema('ajustes-estoque', AjusteEstoqueListSchema);
  addFormSchema('ajustes-estoque', AjusteEstoqueFormSchema);

  ARMAZENAMENTO_MENU.children.push(AJUSTE_ESTOQUE_LIST);

  return [];

}
