import BandeiraSelector from "../Bandeira/Component/BandeiraSelector";

const PortoFormSchema = () => {
  const baseEndpoint = 'portos'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Porto",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          codigo: {
            type: "string",
            title: "Código"
          },
          bandeira: {
            type: "object",
            title: "Bandeira",
            properties: {
              sigla: {type: "string", title: "Categoria"}
            }
          },
          transitTime: {
            type: "number",
            title: "Transit times"
          }
        }
      }
    },

    getUiSchema() {
      return {
        bandeira: {
          "ui:field": (props) => {
            return (<BandeiraSelector disabled={props.disabled}
                                      required={props.required}
                                      showCreateButton={true}
                                      title={props.title}
                                      value={props.formData}
                                      setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default PortoFormSchema;
