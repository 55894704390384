import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = (filterValue) => {
  return `familia-materiais?sort=nome,ASC&nome=${filterValue}`;//TODO SETAR URl
}

const FamiliaMaterialSelector = ({
  required = false,
  defaultValue,
  title = "Familia Material",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={true}
      pathToCreate={'/cadastros/familiaMaterial/form'}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />
}
export default FamiliaMaterialSelector;
