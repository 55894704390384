import BaseSelector from "./BaseSelector";

const yearBase = new Date().getFullYear() - 4;
export const YEAR_TYPES = [];
YEAR_TYPES.push({id: yearBase.toString(), label: yearBase.toString()});
for (let i = 1; i < 10; i++) {
  const data = (yearBase + i).toString();
  YEAR_TYPES.push({id: data, label: data});
}

const YearSelector = ({
  required = false,
  setValue = null,
  onChange,
  title = "Ano",
  ...props
}) => {

  return <BaseSelector
      required={required}
      title={title}
      placeholder="Ano"
      label="id"
      setValue={setValue}
      items={YEAR_TYPES}
      showCreateButton={false}
      {...props}
  />
}

export default YearSelector;
