import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = () => {
  return `grupo-permissao/roles`;
}

const RoleSelector = ({
  required = false,
  defaultValue,
  title = "Roles",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />
}
export default RoleSelector;
