import {Pagination} from "react-bootstrap";

const MAX_ELEMENTS = 6;
export const TablePagination = ({
                                  previousPage,
                                  pageIndex, nextPage, canPreviousPage, canNextPage,
                                  pageCount, gotoPage
                                }) => {

  let items = [];

  let startElement = pageIndex > 3 ? (pageIndex - 3) : 0;

  for (let number = startElement; number < pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === pageIndex} onClick={() => gotoPage(number)}>
        {number + 1}
      </Pagination.Item>,
    );

    if (items.length > MAX_ELEMENTS) {
      break;
    }
  }

  return (<Pagination size="sm" className="justify-content-sm-start justify-content-md-end">
    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage}/>
    <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage}/>
    {items}
    <Pagination.Next onClick={nextPage} disabled={!canNextPage}/>
    <Pagination.Last onClick={() => gotoPage((pageCount - 1))} disabled={!canNextPage}/>
  </Pagination>);

}

