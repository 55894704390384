import moment from 'moment';
import 'moment-timezone';
import Objects from "./Objects";

export const monthDayYear = (timestamp, timezone) => (
  !timezone ? moment(timestamp).format('MMMM Do, YYYY') : moment(timestamp).tz(timezone).format('MMMM Do, YYYY')
);

export const monthDayYearAtTime = (timestamp, timezone) => (
  !timezone ? moment(timestamp).format('MMMM Do, YYYY [at] hh:mm a') : moment(timestamp).tz(timezone).format('MMMM Do, YYYY [at] hh:mm a')
);

const convert = (timestamp, timezone, format) => {
  if (Objects.isNullOrUndefined(timestamp)) {
    return '';
  }
  return !timezone ? moment(timestamp).format(format) : moment(timestamp).tz(timezone).format(format)
};

export const dayMonthYear = (timestamp, timezone) => (
  convert(timestamp, timezone, 'DD/MM/YYYY')
);

export const YearMonthDay = (timestamp, timezone) => (
  convert(timestamp, timezone, 'YYYY-MM-DD')
);

export const HourMinute = (timestamp, timezone) => (
  convert(timestamp, timezone, 'HH:mm')
);

export const HourMinuteSeg = (timestamp, timezone) => (
  convert(timestamp, timezone, 'HH:mm:ss')
);

export const dayMonthYearAtTime = (timestamp, timezone) => (
  convert(timestamp, timezone, 'DD/MM/YYYY HH:mm')
);

export const timeago = (timestamp, timezone) => (
  !timezone ? moment(timestamp).fromNow() : moment(timestamp).tz(timezone).fromNow()
);

export const add = (timestamp, amount, range, timezone) => (
  !timezone ? moment(timestamp).add(amount, range).format() : moment(timestamp).tz(timezone).add(amount, range).format()
);

export const year = (timestamp, timezone) => (
  !timezone ? moment(timestamp).format('YYYY') : moment(timestamp).tz(timezone).format('YYYY')
);

export const getLastDayOfMonths = (month,year) => {
  var date = new Date();
  date.setMonth(month);
  date.setFullYear(year);
  const startDate = moment(date).startOf('month').toISOString();
  const endDate = moment(date).endOf('month').toISOString();
  return {startDate, endDate}
}

const weekday = [
  {id:"Sunday", portugues:"Domingo"},
  {id:"Monday", portugues:"Segunda"},
  {id:"Tuesday", portugues:"Terça"},
  {id:"Wednesday", portugues:"Quarta"},
  {id:"Thursday", portugues:"Quinta"},
  {id:"Friday", portugues:"Sexta"},
  {id:"Saturday", portugues:"Sábado"}
  ];
export const dayOfWeek = (timestamp, timezone) => (
    !timezone
        ?
        weekday.find(x => x.id === moment(timestamp).format('dddd')).portugues
        :
        weekday.find(x => x.id === moment(timestamp).tz(timezone).format('dddd')).portugues
);