const PatioFormSchema = () => {
  const baseEndpoint = 'patios'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Pátio",
        type: "object",
        properties: {
          codigo: {
            type: "string",
            title: "Código do pátio",
            placeholder: "P-0"
          },
          descricao: {
            type: "string",
            title: "Descrição"
          },
          quantidadeBalizas: {
            type: "integer",
            title: "Quantidade de balizas"
          },
          ativo: {
            type: "boolean",
            title: "Ativo"
          },
          referenciaInicio: {
            type: "string",
            title: "Referencia Início",
            placeholder: "SD-1"
          },
          referenciaFim: {
            type: "string",
            title: "Referencia Fim",
            placeholder: "TC-27"
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        codigo: {
          "ui:options": {
            placeholder: "P-01"
          }
        },
        referenciaInicio:{
          "ui:options": {
            placeholder: "SD-1"
          }
        },
        referenciaFim:{
          "ui:options": {
            placeholder: "TC-27"
          }
        },
      }
    }
  }
}

export default PatioFormSchema;
