import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {PortoListSchema} from "./PortoListSchema";
import PortoFormSchema from "./PortoFormSchema";

export const PortoModule = () => {


  const PORTO_LIST = MenuItem({
    name: 'Porto',
    path: 'porto',
    icon: 'fa fa-anchor',
    canCreateRoute: false
  });

  addListSchema('porto', PortoListSchema);
  addFormSchema('porto', PortoFormSchema);

  CADASTRO_MENU.children.push(PORTO_LIST);

  return [];

}
