import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const MapTbn = (item) => {
  if (item) {
    item.nome = `${item?.numero}/${item?.ano}`
  }
  return item;
}

const getUrl = () => {
  return `v1/combobox/tbns/agendamento-ptc`;
}

const filterItemsFn = (item) => {
  return MapTbn(item);
}

const TBNSelector = ({
  required = false,
  defaultValue,
  title = "TBN",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      filterItems={filterItemsFn}
      {...props}
  />
}
export default TBNSelector;
