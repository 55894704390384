import {Accordion, Card} from "react-bootstrap";
import {formatarCodigoPilha} from "../../Pilha/Component/PilhaFormatter";
import MovimentacaoPilha from "./MovimentacaoPilha";
import React from "react";
import {AccordionToggleComSeta} from "./AccordionToggleComSeta";


function PilhaMaterial({materialId, pilhas}) {
    const pilhasPorMaterial = pilhas?.filter(x => x.material.id === materialId);
    return <>
        {pilhasPorMaterial?.length <= 0
            ?
            <>Sem pilhas cadastradas para o material/navio.</>
            :
            <Accordion defaultActiveKey={pilhasPorMaterial && pilhasPorMaterial[0]?.id}>
                {pilhasPorMaterial?.map(pilha =>
                    <Card className="bg-light" key={pilha.id}>
                        <Card.Header>
                            <AccordionToggleComSeta className="col-12 border-0 bg-transparent" variant="link"
                                                    eventKey={pilha.id}>
                                {"Pilha: " + formatarCodigoPilha(pilha.codigoPilha.numero, pilha.codigoPilha.ano)}
                            </AccordionToggleComSeta>
                        </Card.Header>
                        <Accordion.Collapse eventKey={pilha.id}>
                            <Card.Body>
                                <MovimentacaoPilha pilhaId={pilha.id}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>)
                }
            </Accordion>
        }
    </>;
}

export default PilhaMaterial;