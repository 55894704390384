import {Link} from "react-router-dom";
import {Gravatar} from "../Gravatar/Gravatar";
import {UserContext} from "../../Context/UserContext";
import {useContext} from "react";

export const CurrentUser = () => {

  const {currentUser} = useContext(UserContext);

  return (
      <div>
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
          </div>
          <div className="info" style={{marginTop: -10, marginBottom: -10}}>
            <Link to="/me" className="d-block text-light">
              {currentUser.nome}
              <br/>
              <span style={{fontSize: "0.75rem"}}>{currentUser?.company?.nome} </span>
            </Link>
          </div>
        </div>
      </div>
  )
}
