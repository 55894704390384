import React from "react";
import {DropdownButton} from "react-bootstrap";
import {DeleteButton} from "./DeleteButton";
import {EditButton} from "./EditButton";
import {flexRender} from "@tanstack/react-table";
import {CreateCargaButton} from "../../../../CargaNavioPTC/Component/CreateCargaButton";

export const TableButtonGroup = ({row, children, setGlobalFilter, deleteEndpoint, showEditButton, showDeleteButton, editEndpoint, showCreateCargaButton, createEndpoint}) => {

  return (<DropdownButton variant="secondary" size="sm"
                          title={<i className="fa fa-bars" aria-hidden="true"></i>}>

        {showEditButton && <EditButton row={row} editEndpoint={editEndpoint}/>}
        {showCreateCargaButton && <CreateCargaButton row={row} createEndpoint={createEndpoint}/>}
        {showDeleteButton && <DeleteButton row={row} deleteEndpoint={deleteEndpoint} updateTable={() => setGlobalFilter(new Date())}/>}
        {children}
      </DropdownButton>
  )
}

export const TableButtonOptions = ({
  children, deleteEndpoint, editEndpoint,createEndpoint, showEditButton = true, showDeleteButton = true, approveEndpoint,
  showAproveButton = false, showAproveButtonFn, showCreateCargaButton = false
}) => {
  return {
    header: ' ',
    accessorKey: '_options',
    enableSorting: false,
    export: false,
    collapse: true,
    maxWidth: '60',
    enableColumnFilter: false,
    cell: ({row, table, cell}) => {
      return (
          <div className="text-center p-1">
            <TableButtonGroup row={row} setGlobalFilter={table.setGlobalFilter} showAproveButtonFn={showAproveButtonFn} deleteEndpoint={deleteEndpoint} showEditButton={showEditButton}
                              showDeleteButton={showDeleteButton} createEndpoint={createEndpoint} showAproveButton={showAproveButton} showCreateCargaButton={showCreateCargaButton} approveEndpoint={approveEndpoint} editEndpoint={editEndpoint} >
              {children && flexRender(
                  children,
                  cell.getContext()
              )}
            </TableButtonGroup>
          </div>
      )
    }
  }
}
