import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const FornecedorListSchema = () => {
  const endpoint = 'fornecedores';

  return {
    getTitle: () => {
      return 'Lista de Fornecedores'
    },

    getEndpoint: () => endpoint,
    //TODO ajustar Listas

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },
        {
          header: "País",
          accessorKey: "pais"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },


  }
}


