const BandeiraFormSchema = () => {
  const baseEndpoint = 'bandeiras'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Bandeira",
        type: "object",
        properties: {
          sigla: {
            type: "string",
            title: "Sigla"
          },
          descricao: {
            type: "string",
            title: "Descrição"
          },
        }
      }
    }
  }
}

export default BandeiraFormSchema;
