import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {dayMonthYear} from "../../../Common/Utils/DateFormat";

export const MapAgendamentoItem = (item) => {
  if (item) {
    item.nome = `TBN: ${item?.tbn?.numero}/${item?.tbn?.ano} - Navio: ${item?.navio?.nome} - ETA D: ${dayMonthYear(item?.etaD)} `
  }
  return item;
}

const getUrl = () => {
  return `agendamentos-ptc?`;
}

const filterItemsFn = (item) => {
  return MapAgendamentoItem(item);
}

const AgendamentoPTCSelector = ({
  required = false,
  defaultValue,
  title = "Agendamento PTC",
  setValue = null,
  ...props
}) => {


  return <BaseSelector
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    filterItems={filterItemsFn}
    {...props}
  />
}
export default AgendamentoPTCSelector;
