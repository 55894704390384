import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import FamiliaMaterialSelector from "../FamiliaDeMaterial/Component/FamiliaMaterialSelector";

export const MaterialListSchema = () => {
  const endpoint = 'materiais';

  return {
    getTitle: () => {
      return 'Lista de Materiais'
    },
    //TODO ajustar Listas

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },
        {
          header: "Descrição",
          accessorKey: "descricao"
        },
        {
          header: "Sigla",
          accessorKey: "sigla"
        },
        {
          id:"FamiliaMaterial.Id",
          header: "Família Material",
          accessorKey: "familiaMaterial",
          customFilterSelectorElement: FamiliaMaterialSelector ,
          cell: (item) => item.row.original.familiaMaterial?.nome
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


