import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const PermissoesListSchema = () => {

  const endpoint = 'grupo-permissao';

  return {
    getTitle: () => {
      return 'Lista de Permissões'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "ROLES",
          accessorKey: "roleSistem"
        },
        {
          header: "Grupo AD",
          accessorKey: "grupoAcessoAd"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


