import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {PTC_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {PTCListSchema} from "./PTCListSchema";
import PTCFormSchema from "./PTCFormSchema";
import PTCPaginaInicial from "./PTCPaginaInicial";
import PTCCloneFormSchema from "./PTCCloneFormSchema";
import {Roles} from "../Auth/Roles";


export const PTCModule = () => {

  const PTC_LIST = MenuItem({
    name: 'Lista PTC',
    path: 'ptc',
    icon: 'fal fa-calendar-alt',
    canCreateRoute: false,
    roles: [Roles.VER_PTC, Roles.MANTER_PTC]
  });

  const PTC_PAGE = MenuItem({
    name: 'Página Inicial PTC',
    path: `ptc-page`,
    icon: 'fa fa-file-import',
    element: <PTCPaginaInicial/>,
    roles: [Roles.VER_PTC, Roles.MANTER_PTC]
  });

  addListSchema('ptc', PTCListSchema);
  addFormSchema('ptc', PTCFormSchema);
  addFormSchema('ptc-clone', PTCCloneFormSchema);

  PTC_MENU.children.push(PTC_PAGE);
  PTC_MENU.children.push(PTC_LIST);

  return [];

}
