import React from "react";
import {Col, Row} from "react-bootstrap";
import TablePageSelector from "./TablePageSelector";
import {TablePagination} from "./TablePagination";

export const TableFooter = ({
                              table, totalElements
                            }) => {

  const {getState, setPageIndex, nextPage, previousPage, getCanPreviousPage, setPageSize} = table;
  const state = getState();

  const {pagination} = state;
  const pageIndex = pagination.pageIndex;
  const totalSize = table.getRowModel().rows.length;
  const pageCount = Math.ceil(totalElements / pagination.pageSize);
  const canNextPage = pageIndex < (pageCount);

  return (<tfoot>
    <tr>
      <td colSpan="100">
        <Row className="mt-1" style={{width: '100%'}}>
          <Col sm={12} md={7} className="mt-2  mb-2">
            Apresentando {totalSize} de {totalElements}{' '} resultados
          </Col>

          <Col sm={12} md={2} className="mt-1 mb-2">
            <TablePageSelector pageSize={pagination.pageSize} setValue={setPageSize}/>
          </Col>

          <Col sm={12} md={3} className=" mt-1 ">
            <TablePagination previousPage={previousPage} pageCount={pageCount}
                             canNextPage={canNextPage}
                             canPreviousPage={getCanPreviousPage()} gotoPage={setPageIndex}
                             nextPage={nextPage} pageIndex={pageIndex} pageSize={pagination.pageSize}
            />
          </Col>

        </Row>
      </td>
    </tr>
    </tfoot>

  )
}

