import {dayMonthYear, dayOfWeek} from "../../Common/Utils/DateFormat";

export const VolumeDiarioListSchema = () => {
  const endpoint = 'volumes-diarios';

  return {
    getTitle: () => {
      return 'Consolidado do estoque físico'
    },

    getHeader: () => {
      return {
        showNewButton: false,
      }
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Dia",
          enableColumnFilter: false,
          accessorFn: (row) => dayOfWeek(row.data),
        },
        {
          header: "Data",
          accessorKey: "data",
          dateRange: true,
          accessorFn: (row) => dayMonthYear(row.data),
        },
        {
          header: "Carvão Coqueria",
          accessorKey: "carvaoCoqueria",
          enableColumnFilter: false
        },
        {
          header: "Carvão PCI",
          accessorKey: "carvaoPci",
          enableColumnFilter: false
        },
        {
          header: "Carvão Térmico",
          accessorKey: "carvaoTermico",
          enableColumnFilter: false
        },
        {
          header: "Total Carvão",
          accessorKey: "totalCarvao",
          enableColumnFilter: false
        },
        {
          header: "Coque",
          accessorKey: "coque",
          enableColumnFilter: false
        },
        {
          header: "Petcoke",
          accessorKey: "petcoke",
          enableColumnFilter: false
        },
        {
          header: "Moinha de coque",
          accessorKey: "moinhaCoque",
          enableColumnFilter: false
        },
        {
          header: "Pelota importação",
          accessorKey: "pelotaImportacao",
          enableColumnFilter: false
        },
        {
          header: "Pelota Cabotagem",
          accessorKey: "pelotaCabotagem",
          enableColumnFilter: false
        },
        {
          header: "Finos de pelota",
          accessorKey: "finosPelota",
          enableColumnFilter: false
        },
        {
          header: "Total redutores",
          accessorKey: "totalRedutores",
          enableColumnFilter: false
        },
        {
          header: "Total Geral",
          accessorKey: "total",
          enableColumnFilter: false
        },
      ]
    }
  }
}


