import HttpRequest from "../../Http/HttpRequest";
import {useEffect, useState} from "react";

export const Footer = () => {
  const [versao, setVesao] = useState(null);

  const buscarVersaoBE = async () => {
    const versao = await HttpRequest.get(`v1/Version/GetCurrentVersion`);
    setVesao(versao);
  }

  useEffect(() => {
    buscarVersaoBE();
  }, []);

  return (
    <footer className="main-footer blockquote-footer">
      Desenvolvido por <strong><a href={"https://gttlogistics.com.br/"} title="Code by cflow.com.br">GTT</a></strong> &nbsp; - &nbsp;
      <strong className="mr-2">
        Copyright © {new Date().getFullYear()}
        <a className="ml-2" href="https://
          csn.com.br">CSN</a>
      </strong>
      Todos Direitos Reservados.&nbsp;
      <div className="float-right d-none d-sm-inline-block">
        &nbsp;<strong>Versão API:</strong>&nbsp;{versao}
      </div>
      <div className="float-right d-none d-sm-inline-block">
        <b>Versão Front-end</b> {process.env.REACT_APP_BUILD_DATE || 'DEV_MODE'} &nbsp;
      </div>
    </footer>
  )
}
