import {Link} from "react-router-dom";
import React from "react";

const MENUS = [];

const getMenusByUser = (menus, currentUser) => {

  const roles = currentUser.authorities;
  return menus.filter(x => canDoAccess(x.roles, roles));
}

const canDoAccess = (menuRoles = [], userRoles) => {
  if (menuRoles.length === 0) {
    return true;
  }

  for (const role of menuRoles) {
    if (userRoles.indexOf(role) >= 0) {
      return true;
    }
  }

  return false;
}

export const MenuService = {

  getMenu: (id) => {
    return MENUS.find(x => x.id === id);
  },
  getMenus: (currentUser) => {
    return MenuService.getOnlyMenus(MENUS, currentUser);
  },

  getOnlyMenus: (menus, currentUser) => {
    //console.log("currentUser", currentUser)
    return getMenusByUser(menus, currentUser).filter(x => x.canShowInMenu).sort((x, y) => x.order - y.order);
  },

  getRoutes: (currentUser) => {
    return MenuService.getOnlyRoutes(MENUS, currentUser);
  },

  getOnlyRoutes: (menus, currentUser) => {
    return getMenusByUser(menus, currentUser).filter(x => x.canCreateRoute);
  },

  addMenu: (...menus) => {
    MENUS.push(...menus);
  },

  getMenuPath: (...menus) => {
    return menus.map(x => x.path).join('/');
  }

}

export const MapMenusToRoute = (currentUser) => {
  const menus = MenuService.getRoutes(currentUser);
  return menus.map(x => MapRoute(x, currentUser));
}

const getMenuTitle = (menu, params) => {
  if (menu.getTitle) {
    return menu.getTitle(params);
  }

  return menu.name
}
const MapRoute = (menu, currentUser) => {
  // const title = menu.getTitle ? menu.getTitle() : menu.name;

  return {
    path: menu.path, element: menu.element, children: MenuService.getOnlyRoutes(menu.children, currentUser).map(x => MapRoute(x, currentUser)), handle: {
      getName: ({params}) => {
        return getMenuTitle(menu, params);
      }, // you can put whatever you want on a route handle
      // here we use "crumb" and return some elements,
      // this is what we'll render in the breadcrumbs
      // for this route
      crumb: ({pathname, params}) => {
        const name = getMenuTitle(menu, params);
        if (!name) {
          return null;
        }
        return <Link to={pathname}>{name}</Link>
      },
    }
  }

}

export const MenuItem = ({
  canShowInMenu = true, canCreateRoute = true, path, name, icon, element, children = [], getTitle, roles = [], order = 100, newTab = false
}) => {
  return {
    icon, path, order, canShowInMenu, canCreateRoute, children, element, newTab, getTitle, name, roles
  }
}
