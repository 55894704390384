import {BaseTextInput} from "../../../../../Common/Component/Input/BaseTextInput";
import {BaseCheckboxInput} from "../../../../../Common/Component/Input/BaseCheckboxInput";
import {BaseCalendarInput} from "../../../../../Common/Component/Input/BaseCalendarInput";
import {useState} from "react";
import DateRangeSelector from "../../../../../Common/Component/Select/DateRangeSelector";
import FilterParamType from "../TableData/FilterType";

export function DefaultColumnFilter({header}) {

  const column = header.column;
  const value = column.getFilterValue();

  return (
    <BaseTextInput
      label={column.columnDef.header}
      labelRequired={true}
      showFormComponents={false}
      value={value || ''}
      onChange={e => column.setFilterValue(e.target.value)}
      placeholder={`Filtrar resultados...`}
    />
  )
}

export function DefaultColumnFilterSelector({header}) {

  const column = header.column;
  const Selector = header.column.columnDef.customFilterSelectorElement;
  const [value, setValue] = useState(null);

  return <Selector value={value} setFilterColumn={column.setFilterValue} setValue={setValue}/>
}

export function CheckboxColumnFilter({column}) {

  const value = column.getFilterValue();

  return (<BaseCheckboxInput
    label={column.columnDef.header}
    labelRequired={true}
    showFormComponents={false}
    checked={!!value}
    onChange={e => column.setFilterValue(e.target.checked)}
    placeholder={`Filtrar resultados...`}
  />)
}

export function DateRangeColumnFilter2({header}) {

  const column = header.column;
  const value = column.getFilterValue();

  return (<BaseCalendarInput
    label={column.columnDef.header}
    labelRequired={true}
    defaultValue={value}
    selectionMode="range"
    onChange={e => column.setFilterValue(e)}
    placeholder={`Filtrar resultados...`}
  />)
}



export function DateRangeColumnFilter({header}) {

  const column = header.column;
  const value = column.getFilterValue();

  return (<DateRangeSelector
      title={column.columnDef.header}
      defaultValue={value}
      onChange={e => {
        column.setFilterValue({...e, type: FilterParamType.RANGE_DATE});
      }}
  />)
}
