import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const PlantaListSchema = () => {
  const endpoint = 'plantas';

  return {
    getTitle: () => {
      return 'Lista de Plantas'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },

        {
          header: "Código",
          accessorKey: "codigoPlanta"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


