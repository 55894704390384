import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {Col, Form, Row} from "react-bootstrap";
import React from "react";

const DateRangeSelector = ({
                               onChange,
                               defaultStartDate,
                               defaultEndDate,
                               title = "Data",
                               showTitle = true,
                               rowStyle = {},
                           }) => {

    const ranges = {
        'Limpar o filtro': [
            moment().subtract(7, 'days').toDate().setHours(0, 0, 0),
            moment().toDate().setHours(23, 59, 59),
        ],
        Hoje: [
            moment().toDate().setHours(0, 0, 0),
            moment().toDate().setHours(23, 59, 59)
        ],
        Ontem: [
            moment().subtract(1, 'days').toDate().setHours(0, 0, 0),
            moment().subtract(1, 'days').toDate().setHours(23, 59, 59),
        ],
        'Últimos 7 dias': [
            moment().subtract(7, 'days').toDate().setHours(0, 0, 0),
            moment().toDate().setHours(23, 59, 59),
        ],
        'Últimos 30 dias': [
            moment().subtract(29, 'days').toDate().setHours(0, 0, 0),
            moment().toDate().setHours(23, 59, 59),
        ],
        'Este mês': [
            moment().startOf('month').toDate().setHours(0, 0, 0),
            moment().endOf('month').toDate().setHours(23, 59, 59),
        ],
        'Último mês': [
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month'),
        ],
    }

    const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab']

    const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago',
        'Set', 'Out', 'Nov', 'Dez']

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };

    return (
        <Row className="align-items-center" style={{...rowStyle}}>
            <Col md={11} sm={10} xs={10} onClick={e => e.stopPropagation()}>
                <Form.Group>
                    {showTitle && <Form.Label>{title}</Form.Label>}
                    <DateRangePicker
                        onCallback={(startDate, endDate) => onChange && onChange(
                            [
                                startDate.toDate(),
                                endDate.toDate()
                            ]
                        )}
                        onApply={handleApply}
                        onCancel={handleCancel}
                        initialSettings={{
                            timePicker: true,
                            autoUpdateInput: false,
                            ranges,
                            locale: {
                                format: 'DD/MM/YYYY',
                                customRangeLabel: 'Selecione a data',
                                daysOfWeek,
                                monthNames,
                                cancelLabel: 'Clear',
                            },
                            startDate: defaultStartDate,
                            endDate: defaultEndDate,
                        }}
                    >
                        <input type="text" className="form-control"/>
                    </DateRangePicker>
                </Form.Group>
            </Col>
        </Row>


    )
}
export default DateRangeSelector
