import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const PaisSelector = ({
  required = false,
  defaultValue,
  title = "País",
  setValue = PAISES,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="id"
      setValue={setValue}
      items={PAISES}
      {...props}
  />
}
export default PaisSelector;

export const PAISES = [
  {id: "Afeganistão", label: "AFEGANISTÃO"}, {id: "África do Sul", label: "ÁFRICA DO SUL"}, {id: "Albânia", label: "ALBÂNIA"}, {id: "Alemanha", label: "ALEMANHA"}, {id: "Andorra", label: "ANDORRA"}, {id: "Angola", label: "ANGOLA"},
  {id: "Anguilla", label: "ANGUILLA"}, {id: "Antártida", label: "ANTÁRTIDA"}, {id: "Antígua e Barbuda", label: "ANTÍGUA E BARBUDA"}, {id: "Antilhas Holandesas", label: "ANTILHAS HOLANDESAS"}, {id: "Arábia Saudita", label: "ARÁBIA SAUDITA"},
  {id: "Argélia", label: "ARGÉLIA"}, {id: "Argentina", label: "ARGENTINA"}, {id: "Armênia", label: "ARMÊNIA"}, {id: "Aruba", label: "ARUBA"}, {id: "Austrália", label: "AUSTRÁLIA"}, {id: "Áustria", label: "ÁUSTRIA"},
  {id: "Azerbaijão", label: "AZERBAIJÃO"}, {id: "Bahamas", label: "BAHAMAS"}, {id: "Bahrein", label: "BAHREIN"}, {id: "Bangladesh", label: "BANGLADESH"}, {id: "Barbados", label: "BARBADOS"}, {id: "Belarus", label: "BELARUS"},
  {id: "Bélgica", label: "BÉLGICA"}, {id: "Belize", label: "BELIZE"}, {id: "Benin", label: "BENIN"}, {id: "Bermudas", label: "BERMUDAS"}, {id: "Bolívia", label: "BOLÍVIA"}, {id: "Bósnia-Herzegóvina", label: "BÓSNIA-HERZEGÓVINA"},
  {id: "Botsuana", label: "BOTSUANA"}, {id: "Brasil", label: "BRASIL"}, {id: "Brunei", label: "BRUNEI"}, {id: "Bulgária", label: "BULGÁRIA"}, {id: "Burkina Fasso", label: "BURKINA FASSO"}, {id: "Burundi", label: "BURUNDI"},
  {id: "Butão", label: "BUTÃO"}, {id: "Cabo Verde", label: "CABO VERDE"}, {id: "Camarões", label: "CAMARÕES"}, {id: "Camboja", label: "CAMBOJA"}, {id: "Canadá", label: "CANADÁ"}, {id: "Cazaquistão", label: "CAZAQUISTÃO"},
  {id: "Chade", label: "CHADE"}, {id: "Chile", label: "CHILE"}, {id: "China", label: "CHINA"}, {id: "Chipre", label: "CHIPRE"}, {id: "Cingapura", label: "CINGAPURA"}, {id: "Colômbia", label: "COLÔMBIA"}, {id: "Congo", label: "CONGO"},
  {id: "Coréia do Norte", label: "CORÉIA DO NORTE"}, {id: "Coréia do Sul", label: "CORÉIA DO SUL"}, {id: "Costa do Marfim", label: "COSTA DO MARFIM"}, {id: "Costa Rica", label: "COSTA RICA"}, {id: "Croácia (Hrvatska)", label: "CROÁCIA (HRVATSKA)"},
  {id: "Cuba", label: "CUBA"}, {id: "Dinamarca", label: "DINAMARCA"}, {id: "Djibuti", label: "DJIBUTI"}, {id: "Dominica", label: "DOMINICA"}, {id: "Egito", label: "EGITO"}, {id: "El Salvador", label: "EL SALVADOR"},
  {id: "Emirados Árabes Unidos", label: "EMIRADOS ÁRABES UNIDOS"}, {id: "Equador", label: "EQUADOR"}, {id: "Eritréia", label: "ERITRÉIA"}, {id: "Eslováquia", label: "ESLOVÁQUIA"}, {id: "Eslovênia", label: "ESLOVÊNIA"},
  {id: "Espanha", label: "ESPANHA"}, {id: "Estados Unidos", label: "ESTADOS UNIDOS"}, {id: "Estônia", label: "ESTÔNIA"}, {id: "Etiópia", label: "ETIÓPIA"}, {id: "Fiji", label: "FIJI"}, {id: "Filipinas", label: "FILIPINAS"},
  {id: "Finlândia", label: "FINLÂNDIA"}, {id: "França", label: "FRANÇA"}, {id: "Gabão", label: "GABÃO"}, {id: "Gâmbia", label: "GÂMBIA"}, {id: "Gana", label: "GANA"}, {id: "Geórgia", label: "GEÓRGIA"}, {id: "Gibraltar", label: "GIBRALTAR"},
  {id: "Grã-Bretanha (Reino Unido, UK)", label: "GRÃ-BRETANHA (REINO UNIDO, UK)"}, {id: "Granada", label: "GRANADA"}, {id: "Grécia", label: "GRÉCIA"}, {id: "Groelândia", label: "GROELÂNDIA"}, {id: "Guadalupe", label: "GUADALUPE"},
  {id: "Guam (Território dos Estados Unidos)", label: "GUAM (TERRITÓRIO DOS ESTADOS UNIDOS)"}, {id: "Guatemala", label: "GUATEMALA"}, {id: "Guernsey", label: "GUERNSEY"}, {id: "Guiana", label: "GUIANA"},
  {id: "Guiana Francesa", label: "GUIANA FRANCESA"}, {id: "Guiné", label: "GUINÉ"}, {id: "Guiné Equatorial", label: "GUINÉ EQUATORIAL"}, {id: "Guiné-Bissau", label: "GUINÉ-BISSAU"}, {id: "Haiti", label: "HAITI"}, {id: "Holanda", label: "HOLANDA"},
  {id: "Honduras", label: "HONDURAS"}, {id: "Hong Kong", label: "HONG KONG"}, {id: "Hungria", label: "HUNGRIA"}, {id: "Iêmen", label: "IÊMEN"}, {id: "Ilha Bouvet (Território da Noruega)", label: "ILHA BOUVET (TERRITÓRIO DA NORUEGA)"},
  {id: "Ilha do Homem", label: "ILHA DO HOMEM"}, {id: "Ilha Natal", label: "ILHA NATAL"}, {id: "Ilha Pitcairn", label: "ILHA PITCAIRN"}, {id: "Ilha Reunião", label: "ILHA REUNIÃO"}, {id: "Ilhas Aland", label: "ILHAS ALAND"},
  {id: "Ilhas Cayman", label: "ILHAS CAYMAN"}, {id: "Ilhas Cocos", label: "ILHAS COCOS"}, {id: "Ilhas Comores", label: "ILHAS COMORES"}, {id: "Ilhas Cook", label: "ILHAS COOK"}, {id: "Ilhas Faroes", label: "ILHAS FAROES"},
  {id: "Ilhas Falkland (Malvinas)", label: "ILHAS FALKLAND (MALVINAS)"}, {id: "Ilhas Geórgia do Sul e Sandwich do Sul", label: "ILHAS GEÓRGIA DO SUL E SANDWICH DO SUL"},
  {id: "Ilhas Heard e McDonald (Território da Austrália)", label: "ILHAS HEARD E MCDONALD (TERRITÓRIO DA AUSTRÁLIA)"}, {id: "Ilhas Marianas do Norte", label: "ILHAS MARIANAS DO NORTE"}, {id: "Ilhas Marshall", label: "ILHAS MARSHALL"},
  {id: "Ilhas Menores dos Estados Unidos", label: "ILHAS MENORES DOS ESTADOS UNIDOS"}, {id: "Ilhas Norfolk", label: "ILHAS NORFOLK"}, {id: "Ilhas Seychelles", label: "ILHAS SEYCHELLES"}, {id: "Ilhas Solomão", label: "ILHAS SOLOMÃO"},
  {id: "Ilhas Svalbard e Jan Mayen", label: "ILHAS SVALBARD E JAN MAYEN"}, {id: "Ilhas Tokelau", label: "ILHAS TOKELAU"}, {id: "Ilhas Turks e Caicos", label: "ILHAS TURKS E CAICOS"},
  {id: "Ilhas Virgens (Estados Unidos)", label: "ILHAS VIRGENS (ESTADOS UNIDOS)"}, {id: "Ilhas Virgens (Inglaterra)", label: "ILHAS VIRGENS (INGLATERRA)"}, {id: "Ilhas Wallis e Futuna", label: "ILHAS WALLIS E FUTUNA"}, {id: "índia", label: "ÍNDIA"},
  {id: "Indonésia", label: "INDONÉSIA"}, {id: "Irã", label: "IRÃ"}, {id: "Iraque", label: "IRAQUE"}, {id: "Irlanda", label: "IRLANDA"}, {id: "Islândia", label: "ISLÂNDIA"}, {id: "Israel", label: "ISRAEL"}, {id: "Itália", label: "ITÁLIA"},
  {id: "Jamaica", label: "JAMAICA"}, {id: "Japão", label: "JAPÃO"}, {id: "Jersey", label: "JERSEY"}, {id: "Jordânia", label: "JORDÂNIA"}, {id: "Kênia", label: "KÊNIA"}, {id: "Kiribati", label: "KIRIBATI"}, {id: "Kuait", label: "KUAIT"},
  {id: "Laos", label: "LAOS"}, {id: "Látvia", label: "LÁTVIA"}, {id: "Lesoto", label: "LESOTO"}, {id: "Líbano", label: "LÍBANO"}, {id: "Libéria", label: "LIBÉRIA"}, {id: "Líbia", label: "LÍBIA"}, {id: "Liechtenstein", label: "LIECHTENSTEIN"},
  {id: "Lituânia", label: "LITUÂNIA"}, {id: "Luxemburgo", label: "LUXEMBURGO"}, {id: "Macau", label: "MACAU"}, {id: "Macedônia (República Yugoslava)", label: "MACEDÔNIA (REPÚBLICA YUGOSLAVA)"}, {id: "Madagascar", label: "MADAGASCAR"},
  {id: "Malásia", label: "MALÁSIA"}, {id: "Malaui", label: "MALAUI"}, {id: "Maldivas", label: "MALDIVAS"}, {id: "Mali", label: "MALI"}, {id: "Malta", label: "MALTA"}, {id: "Marrocos", label: "MARROCOS"}, {id: "Martinica", label: "MARTINICA"},
  {id: "Maurício", label: "MAURÍCIO"}, {id: "Mauritânia", label: "MAURITÂNIA"}, {id: "Mayotte", label: "MAYOTTE"}, {id: "México", label: "MÉXICO"}, {id: "Micronésia", label: "MICRONÉSIA"}, {id: "Moçambique", label: "MOÇAMBIQUE"},
  {id: "Moldova", label: "MOLDOVA"}, {id: "Mônaco", label: "MÔNACO"}, {id: "Mongólia", label: "MONGÓLIA"}, {id: "Montenegro", label: "MONTENEGRO"}, {id: "Montserrat", label: "MONTSERRAT"}, {id: "Myanma", label: "MYANMA"},
  {id: "Namíbia", label: "NAMÍBIA"}, {id: "Nauru", label: "NAURU"}, {id: "Nepal", label: "NEPAL"}, {id: "Nicarágua", label: "NICARÁGUA"}, {id: "Níger", label: "NÍGER"}, {id: "Nigéria", label: "NIGÉRIA"}, {id: "Niue", label: "NIUE"},
  {id: "Noruega", label: "NORUEGA"}, {id: "Nova Caledônia", label: "NOVA CALEDÔNIA"}, {id: "Nova Zelândia", label: "NOVA ZELÂNDIA"}, {id: "Omã", label: "OMÃ"}, {id: "Palau", label: "PALAU"}, {id: "Panamá", label: "PANAMÁ"},
  {id: "Papua-Nova Guiné", label: "PAPUA-NOVA GUINÉ"}, {id: "Paquistão", label: "PAQUISTÃO"}, {id: "Paraguai", label: "PARAGUAI"}, {id: "Peru", label: "PERU"}, {id: "Polinésia Francesa", label: "POLINÉSIA FRANCESA"},
  {id: "Polônia", label: "POLÔNIA"}, {id: "Porto Rico", label: "PORTO RICO"}, {id: "Portugal", label: "PORTUGAL"}, {id: "Qatar", label: "QATAR"}, {id: "Quirguistão", label: "QUIRGUISTÃO"},
  {id: "República Centro-Africana", label: "REPÚBLICA CENTRO-AFRICANA"}, {id: "República Democrática do Congo", label: "REPÚBLICA DEMOCRÁTICA DO CONGO"}, {id: "República Dominicana", label: "REPÚBLICA DOMINICANA"},
  {id: "República Tcheca", label: "REPÚBLICA TCHECA"}, {id: "Romênia", label: "ROMÊNIA"}, {id: "Ruanda", label: "RUANDA"}, {id: "Rússia (antiga URSS) - Federação Russa", label: "RÚSSIA (ANTIGA URSS) - FEDERAÇÃO RUSSA"},
  {id: "Saara Ocidental", label: "SAARA OCIDENTAL"}, {id: "Saint Vincente e Granadinas", label: "SAINT VINCENTE E GRANADINAS"}, {id: "Samoa Americana", label: "SAMOA AMERICANA"}, {id: "Samoa Ocidental", label: "SAMOA OCIDENTAL"},
  {id: "San Marino", label: "SAN MARINO"}, {id: "Santa Helena", label: "SANTA HELENA"}, {id: "Santa Lúcia", label: "SANTA LÚCIA"}, {id: "São Bartolomeu", label: "SÃO BARTOLOMEU"}, {id: "São Cristóvão e Névis", label: "SÃO CRISTÓVÃO E NÉVIS"},
  {id: "São Martim", label: "SÃO MARTIM"}, {id: "São Tomé e Príncipe", label: "SÃO TOMÉ E PRÍNCIPE"}, {id: "Senegal", label: "SENEGAL"}, {id: "Serra Leoa", label: "SERRA LEOA"}, {id: "Sérvia", label: "SÉRVIA"}, {id: "Síria", label: "SÍRIA"},
  {id: "Somália", label: "SOMÁLIA"}, {id: "Sri Lanka", label: "SRI LANKA"}, {id: "St. Pierre and Miquelon", label: "ST. PIERRE AND MIQUELON"}, {id: "Suazilândia", label: "SUAZILÂNDIA"}, {id: "Sudão", label: "SUDÃO"}, {id: "Suécia", label: "SUÉCIA"},
  {id: "Suíça", label: "SUÍÇA"}, {id: "Suriname", label: "SURINAME"}, {id: "Tadjiquistão", label: "TADJIQUISTÃO"}, {id: "Tailândia", label: "TAILÂNDIA"}, {id: "Taiwan", label: "TAIWAN"}, {id: "Tanzânia", label: "TANZÂNIA"},
  {id: "Território Britânico do Oceano índico", label: "TERRITÓRIO BRITÂNICO DO OCEANO ÍNDICO"}, {id: "Territórios do Sul da França", label: "TERRITÓRIOS DO SUL DA FRANÇA"},
  {id: "Territórios Palestinos Ocupados", label: "TERRITÓRIOS PALESTINOS OCUPADOS"}, {id: "Timor Leste", label: "TIMOR LESTE"}, {id: "Togo", label: "TOGO"}, {id: "Tonga", label: "TONGA"}, {id: "Trinidad and Tobago", label: "TRINIDAD AND TOBAGO"},
  {id: "Tunísia", label: "TUNÍSIA"}, {id: "Turcomenistão", label: "TURCOMENISTÃO"}, {id: "Turquia", label: "TURQUIA"}, {id: "Tuvalu", label: "TUVALU"}, {id: "Ucrânia", label: "UCRÂNIA"}, {id: "Uganda", label: "UGANDA"},
  {id: "Uruguai", label: "URUGUAI"}, {id: "Uzbequistão", label: "UZBEQUISTÃO"}, {id: "Vanuatu", label: "VANUATU"}, {id: "Vaticano", label: "VATICANO"}, {id: "Venezuela", label: "VENEZUELA"}, {id: "Vietnã", label: "VIETNÃ"},
  {id: "Zâmbia", label: "ZÂMBIA"}, {id: "Zimbábue", label: "ZIMBÁBUE"}
];
