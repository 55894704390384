import MaterialSelector from "../Material/Component/MaterialSelector";
import FornecedorSelector from "../Fornecedor/Component/FornecedorSelector";
import PortoSelector from "../Porto/Component/PortoSelector";
import PlantaSelector from "../Planta/Component/PlantaSelector";
import React from "react";
import {getLocalDataForm} from "../FormPage/FormDataLocalService";
import AgendamentoPTCSelector, {MapAgendamentoItem} from "../AgendamentosPTC/Component/AgendamentoPTCSelector";

const CargaNavioPTCFormSchema = () => {
  const baseEndpoint = 'carga-agendamento'
  const agendamentoLocalData = 'agendamentos-ptc';
  const agendamentoPTC = MapAgendamentoItem(getLocalDataForm('agendamentos-ptc', {}));

  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    formatObjectToSave(formData) {
      formData.agendamentoPTC = getLocalDataForm(agendamentoLocalData);
      formData.agendamentoPTCId = formData.agendamentoPTC?.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.agendamentoPTC = formData.agendamentoPTC ? MapAgendamentoItem(formData.agendamentoPTC) : "";
      return formData;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getFooter() {
      return {showAddMoreButton: true}
    },

    redirectOnSave({navigate}) {
      return navigate(`/ptc-menu/ptc-page`);
    },

    resetOnSave({navigate}) {
      return navigate(`/ptc-menu/carga-agendamento/form`);
    },

    getSchema() {
      return {
        title: "Cargas Navio PTC",
        type: "object",
        properties: {
          dataCTRLaydays: {
            type: "string",
            format: "date-time",
            title: "Data CTR Laydays"
          },
          agendamentoPTC: {
            type: "object",
            title: "agendamento",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          dataLiberacaoFrete: {
            type: "string",
            format: "date-time",
            title: "Data Liberação Frete ",
            readOnly: true
          },
          dataCTRNomeacao: {
            type: "string",
            format: "date-time",
            title: "Data CTR Nomeação"
          },
          pesoP: {
            type: "integer",
            title: "Peso P"
          },
          pesoR: {
            type: "integer",
            title: "Peso R"
          },
          material: {
            type: "object",
            title: "Material",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          fornecedor: {
            type: "object",
            title: "Fornecedor",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          porto: {
            type: "object",
            title: "Porto",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          layd: {
            type: "string",
            format: "date-time",
            title: "LAYD"
          },
          canc: {
            type: "string",
            format: "date-time",
            title: "CANC"
          },
          etaC: {
            type: "string",
            format: "date-time",
            title: "ETA C"
          },
          etbC: {
            type: "string",
            format: "date-time",
            title: "ETB C"
          },
          dataBl: {
            type: "string",
            format: "date-time",
            title: "DATA BL"
          },
          tempoCarregamentoOrigem: {
            type: "number",
            title: "Tempo de carregamento origem", //Preenchido automaticamente, campo somente de leitura. (Data BL – ETB C)
            readOnly: true
          },
          filaPortoOrigem: {
            type: "number",
            title: "Fila Porto de origem", //Preenchido automaticamente, campo somente de leitura. (ETB C – ETA C)
            readOnly: true
          },
          planta: {
            type: "object", title: "Planta", properties: {
              nome: {type: "string", title: "Categoria"}
            }
          },
          fila: {
            type: "number",
            title: "Fila", //Preenchido automaticamente, campo somente de leitura. (Início - ETA D)
            readOnly: true
          },
          cargaStr: {
            type: "string",
            title: "Carga"
          },
          dem: {
            type: "integer",
            title: "DEM"
          },
          prazoPagamento: {
            type: "integer",
            title: "Prazo de Pagamento"
          },
          fob: {
            type: "number",
            title: "FOB"
          },
          frete: {
            type: "number",
            title: "Frete"
          },
          crf: {
            type: "number",
            title: "CRF",//Preenchido automaticamente, campo somente de leitura. (FOB + Frete)
            readOnly: true
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        "agendamentoPTC": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<AgendamentoPTCSelector disabled={props.disabled}
                                            required={props.required}
                                            title={props.title}
                                            value={props.formData == null ? agendamentoPTC : props.formData}
                                            setValue={props.onChange}/>
            );
          }
        },
        "material": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<MaterialSelector disabled={props.disabled}
                                      required={props.required}
                                      title={props.title}
                                      value={props.formData}
                                      setValue={props.onChange}/>
            );
          }
        },
        "fornecedor": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<FornecedorSelector disabled={props.disabled}
                                        required={props.required}
                                        title={props.title}
                                        value={props.formData}
                                        setValue={props.onChange}/>
            );
          }
        },
        "porto": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PortoSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "planta": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PlantaSelector disabled={props.disabled}
                                    required={props.required}
                                    title={props.title}
                                    value={props.formData}
                                    setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default CargaNavioPTCFormSchema;
