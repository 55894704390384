import PaisSelector, {PAISES} from "../../Common/Component/Select/PaisSelector";

const FornecedorFormSchema = () => {
  const baseEndpoint = 'fornecedores'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.pais = formData.pais?.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.pais = PAISES.find(x => x.id === formData.pais);
      return formData;
    },

    getSchema() {
      return {
        title: "Fornecedor",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          pais: {
            title: "País",
            type: 'object',
            properties: {
              id: {type: "string", title: "País"},
              label: {type: "string", title: "País"}
            }
          }

        }
      }
    },
    getUiSchema: () => {
      return {
        pais: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PaisSelector disabled={props.disabled}
                                        required={props.required}
                                        title={props.title}
                                        value={props.formData}
                                        showCreateButton={false}
                                        setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default FornecedorFormSchema;
