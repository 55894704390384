import HttpRequest from "../../../../../Common/Http/HttpRequest";
import FilterParamType from "./FilterType";

export const getTableData = ({pageIndex, pageSize, sortBy, filters, baseEndpoint}) => {

    const params = new URLSearchParams();
    // const urlWithSortAndPagination = url.indexOf('?') === -1 ? `${url}?` : url;
    getSortList(params, sortBy);

    processFilter(params, filters);

    params.append('page', pageIndex);
    params.append('size', pageSize);
    const separator = getSeparator(baseEndpoint);
    const url = `${baseEndpoint}${separator}${params.toString()}`
    return doRequest(url, pageSize);

};

const getFilterValue = (value) => {
    if (value instanceof Date) {
        if (value.onlyDate) {
            return value.toLocaleDateString();
        }
        return value.toISOString();
    }

    return value;
}
const processFilter = (query, filters) => {
    filters && filters.forEach((x) => {
        if (x.id === "duracao" || x.value?.type === FilterParamType.RANGE_DATE) {
            query.append(x.id, `${getFilterValue(x.value[0])},${getFilterValue(x.value[1])}`);

        } else if (x.value && x.value.id && typeof x.value === 'object') {
            query.append(x.id, x.value.id);
        } else {
            if (Array.isArray(x.value)) {
                x.value.forEach(y => {
                    const value = getFilterValue(y);
                    value && query.append(x.id, value);
                });
            } else {
                query.append(x.id, getFilterValue(x.value));
            }

        }
    })
}

const getSortList = (params, sortBy) => {
    console.log('sortBy', sortBy);
    sortBy.forEach(x => {
        const sentido = x.desc ? 'DESC' : 'ASC';
        params.append('sort', `${x.id},${sentido}`);
    });
}

const getSeparator = (url) => {
    return url.indexOf('?') === -1 ? `?` : '&'
}

const doRequest = async (url, pageSize) => {
    const response = await HttpRequest.get(url);
    let content = response.content;

    let totalElements = response.totalElements; // DEVE VIR DO HEADER  response.totalElements;

    return {content, pageCount: Math.ceil(totalElements / pageSize), totalElements};
}
