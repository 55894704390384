import React from "react";
import {flexRender} from "@tanstack/react-table";

const MapColumn = (header) => {
  if (header.isPlaceholder) {
    return null;
  }
  return <>
    <div
        {...{
          className: header.column.getCanSort() ? 'cursor-pointer select-none'
              : '',
          onClick: header.column.getToggleSortingHandler(),
        }}>
      {flexRender(
          header.column.columnDef.header,
          header.getContext()
      )}
      <span>
                  {{
                    asc: ' 🔼',
                    desc: ' 🔽',
                  }[header.column.getIsSorted()] ?? null}
                </span>
    </div>
  </>
}
export const TableHead = ({headerGroups, getRowCanExpand}) => {

  return (<thead className="thead-dark text-center ">
      {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
                <th className="align-middle" key={header.id} colSpan={header.colSpan}>
                  {MapColumn(header)}
                </th>
            ))}
            {getRowCanExpand && getRowCanExpand() && <th></th>}
          </tr>

      ))}
      </thead>
  )
}

