import HttpRequest from "../../Common/Http/HttpRequest";
import {saveLocalDataForm} from "./FormDataLocalService";

export const getFormData = async ({id, formSchema}) => {

  if (!id) {
    if(formSchema.formatObjectToCreate){
      return formSchema.formatObjectToCreate({});
    }
    return;
  }

  const url = formSchema.getUrlFindById(id);
  return HttpRequest.get(url).then(entity => {

    if (formSchema.formatObjectToGet) {
      entity = formSchema.formatObjectToGet(entity);
    }

    return removeNullFields(entity);
  });
}

const removeNullFields = (entity) => {

  Object.keys(entity).forEach(x => {

    if (entity[x] === null) {
      delete entity[x];
    }

  })

  return entity;
}

function saveFormDataServer(pageSchema, entity) {
  if (!pageSchema.dataId) {
    return doPost({formData: entity, pageSchema});
  }
  return doPut({formData: entity, pageSchema});
}

export const saveFormData = ({formData, pageSchema}) => {
  let entity = removeOptionalEmptyValues(formData);
  if (pageSchema.formatObjectToSave) {
    entity = pageSchema.formatObjectToSave(entity, pageSchema.originalFormData);
  }
  return saveFormDataServer(pageSchema, entity).then(x => {
    const name = pageSchema.fieldToSaveName || pageSchema.entity;
    saveLocalDataForm(name, x);
    return x;
  });
};

const doPost = async ({formData, pageSchema}) => {
  const postUrl = pageSchema.getSaveUrl('', formData);
  return HttpRequest.post(postUrl, formData);
}

const doPut = async ({formData, pageSchema}) => {
  const postUrl = pageSchema.getSaveUrl(pageSchema.dataId, formData);
  return HttpRequest.put(postUrl, formData);
}

const removeOptionalEmptyValues = (data) => {
  for (let field in data) {
    let value = data[field];
    if (!value) {
      continue;
    }
    if (typeof value !== 'object') {
      continue;
    }
    let isEmpty = Object.keys(value).length === 0;
    if (isEmpty) {
      data[field] = undefined;
    }
  }
  return data;
}
