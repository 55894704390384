import NavioSelector from "../Navio/Component/NavioSelector";
import ArmadorSelector from "../Armador/Component/ArmadorSelector";
import PTCSelector, {MapPtc} from "../PTC/Component/PTCSelector";
import {formatarTBN} from "../TBN/Component/TBNFormatter";
import {getLocalDataForm} from "../FormPage/FormDataLocalService";

const AgendamentoPTCFormSchema = () => {
  const baseEndpoint = 'agendamentos-ptc';

  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.ptc = formData.ptc === undefined ? getLocalDataForm('ptc', {}) : formData.ptc;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.tbnFriendly = formData.tbn ? formatarTBN(formData.tbn.numero, formData.tbn.ano) : " ";
      formData.ptc = formData.ptc ?  MapPtc(formData.ptc) : " ";
      return formData;
    },

    redirectOnSave({navigate, eventToSave}) {
      if (!eventToSave.id) {
        return navigate("/cadastros/carga-agendamento/form");
      }
      return navigate(-1)
    },

    getSchema() {
      return {
        title: "Agendamento do PTC: ",
        type: "object",
        properties: {
          tbn: {
            type: "object",
            title: "TBN",
            properties: {
              nome: {type: 'string', title: 'TBN'}
            }
          },
          ptc: {
            type: "object",
            title: "PTC",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          tbnFriendly: {
            type: "string",
            title: "TBN",
            readOnly: true
          },
          navio: {
            type: "object",
            title: "Navio",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          armador: {
            type: "object",
            title: "Armador",
            properties: {
              nome: {type: 'string', title: 'Nome'}
            }
          },
          etaD: {
            type: "string",
            format: "date-time",
            title: "ETA D"
          },
          inicioDesembarque: {
            type: "string",
            format: "date-time",
            title: "Data Inicio de Desembarque"
          },
          fimDesembarque: {
            type: "string",
            format: "date-time",
            title: "Data de Fim do Desembarque"
          },
        },
      }
    },

    getUiSchema: () => {
      return {
        "tbn": {
          "ui:options": {
            label: false
          },
          "ui:widget": "hidden"
        },
        "tbnFriendly": {
          "ui:placeholder": "Gerado automaticamente"
        },
        "navio": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<NavioSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        "armador": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<ArmadorSelector disabled={props.disabled}
                                     required={props.required}
                                     title={props.title}
                                     value={props.formData}
                                     setValue={props.onChange}/>
            );
          }
        },
        "ptc": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PTCSelector disabled={props.disabled}
                                 required={props.required}
                                 title={props.title}
                                 value={props.formData == null ? MapPtc(getLocalDataForm('ptc', {})) : props.formData}
                                 setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default AgendamentoPTCFormSchema;
