import FamiliaMaterialSelector from "../FamiliaDeMaterial/Component/FamiliaMaterialSelector";

const MaterialFormSchema = () => {
  const baseEndpoint = 'materiais'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Família de Material",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          descricao: {
            type: "string",
            title: "Descrição"
          },
          sigla: {
            type: "string",
            title: "Sigla"
          },
          familiaMaterial: {
            title: "Família Material",
            type: 'object',
            properties: {
              nome: {type: "string", title: "Família Material"}
            }
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        "familiaMaterial": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<FamiliaMaterialSelector disabled={props.disabled}
                                          required={props.required}
                                          title={props.title}
                                          value={props.formData}
                                          setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default MaterialFormSchema;
