import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {dayMonthYearAtTime} from "../../Common/Utils/DateFormat";
import {formatarCodigoPilha} from "../Pilha/Component/PilhaFormatter";

export const AjusteEstoqueListSchema = () => {

  const endpoint = 'movimentacoes?MeioMovimentacao=AJUSTE';

  return {
    getTitle: () => {
      return 'Lista de Ajustes de Estoque'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Pilha",
          accessorKey: "pilha",
          accessorFn: (row) => formatarCodigoPilha( row.pilha?.codigoPilha?.numero, row.pilha?.codigoPilha?.ano)
        },
        {
          header: "Tipo de movimentação",
          accessorKey: "tipoMovimentacao",
          accessorFn: (row) => row.tipoMovimentacao === "DEPOSITO" ? "Depósito" : "Retirada"
        },
        {
          header: "Data da movimentação",
          accessorKey: "data",
          accessorFn: (row) => dayMonthYearAtTime(row.data)
        },
        {
          header: "Volume (t)",
          accessorKey: "volume"
        },
        {
          header: "Justificativa",
          accessorKey: "observacao"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


