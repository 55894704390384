import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {NavioListSchema} from "./NavioListSchema";
import NavioFormSchema from "./NavioFormSchema";

export const NavioModule = () => {


  const NAVIO_LIST = MenuItem({
    name: 'Navio',
    path: 'navio',
    icon: 'fa fa-ship',
    canCreateRoute: false
  });

  addListSchema('navio', NavioListSchema);
  addFormSchema('navio', NavioFormSchema);

  CADASTRO_MENU.children.push(NAVIO_LIST);

  return [];

}
