import {addFormSchema} from "../FormPage/FormSchemaService";
import AgendamentoPTCFormSchema from "./AgendamentoPTCFormSchema";
import {AgendamentoPTCListSchema} from "./AgendamentoPTCListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";

export const AgendamentoPTCModule = () => {


  // const AGENDAMENTO_PTC_LIST = MenuItem({
  //   name: 'AgendamentoPTC',
  //   path: 'agendamentoPTC',
  //   icon: 'fa fa-user',
  //   canCreateRoute: false
  // });

  addListSchema('agendamentos-ptc', AgendamentoPTCListSchema);
  addFormSchema('agendamentos-ptc', AgendamentoPTCFormSchema);

  // CADASTRO_MENU.children.push(AGENDAMENTO_PTC_LIST);

  return [];

}
