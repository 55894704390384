import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {MovimentacaoListSchema} from "./MovimentacaoListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";
import MovimentacaoFormSchema from "./MovimentacaoFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";

export const MovimentacaoModule = () => {


  const MOVIMENTACAO_LIST = MenuItem({
    name: 'Movimentação',
    path: 'movimentacao',
    icon: 'fa fa-sync',
    canCreateRoute: false
  });

  addListSchema('movimentacao', MovimentacaoListSchema);
  addFormSchema('movimentacao', MovimentacaoFormSchema);

  ARMAZENAMENTO_MENU.children.push(MOVIMENTACAO_LIST);

  return [];

}
