import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CONFIGURACAO_MENU} from "../Modules.routes";
import {PermissoesListSchema} from "./PermissoesListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";
import ArmadorFormSchema from "./PermissoesFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {Roles} from "../Auth/Roles";

export const PermissoesModule = () => {


  const PERMISSOES_LIST = MenuItem({
    name: 'Permissões',
    path: 'permissoes',
    icon: 'fa fa-user',
    canCreateRoute: false,
    roles: [Roles.CONFIGURAR_PERMISSAO]
  });

  addListSchema('permissoes', PermissoesListSchema);
  addFormSchema('permissoes', ArmadorFormSchema);

  CONFIGURACAO_MENU.children.push(PERMISSOES_LIST);

  return [];

}
