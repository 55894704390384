import PTCSelector from "./Component/PTCSelector";
import YearSelector from "../../Common/Component/Select/YearSelector";

const PTCFormSchema = () => {
  const baseEndpoint = 'ptc'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.ptcBase = formData.ptcBase?.id;
      formData.ano = formData.ano.id;
      return formData;
    },

    formatObjectToGet(formData) {
      return formData;
    },

    getSchema() {
      return {
        title: "Novo PTC",
        type: "object",
        properties: {
          ano: {
            type: "object",
            title: "Ano",
          },
          descricao: {
            type: "string",
            title: "Descrição"
          },
        },
      }
    },

    getUiSchema: () => {
      return {
        ano: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<YearSelector disabled={props.disabled}
                                  required={props.required}
                                  title={props.title}
                                  value={props.formData}
                                  setValue={props.onChange}/>
            );
          }
        },
        ptcBase: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PTCSelector disabled={props.disabled}
                                 required={props.required}
                                 title={props.title}
                                 value={props.formData}
                                 setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default PTCFormSchema;
