import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const BandeiraListSchema = () => {
  const endpoint = 'bandeiras';

  return {
    getTitle: () => {
      return 'Lista de Bandeiras'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Sigla",
          accessorKey: "sigla"
        },

        {
          header: "Descrição",
          accessorKey: "descricao"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


