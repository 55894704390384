import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const TIPO_MOVIMENTACAO = [
  {id: "DEPOSITO", label: "Depósito"},
  {id: "RETIRADA", label: "Retirada"},
];

const TipoMovimentacaoSelector = ({
  required = false,
  title = "Tipo de movimentação",
  setValue = TIPO_MOVIMENTACAO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      items={TIPO_MOVIMENTACAO}
      {...props}
  />
}
export default TipoMovimentacaoSelector;
