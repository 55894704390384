import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = (filterValue) => {
  return `portos?sort=nome,ASC&name=${filterValue}`;//TODO SETAR URl
}

const PortoSelector = ({
  required = false,
  defaultValue,
  title = "Porto",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    {...props}
  />
}
export default PortoSelector;
