import React, {useEffect, useState} from "react";
import {Page} from "../../Common/Component/Page/Page";
import {useNavigate} from "react-router-dom";
import HttpRequest from "../../Common/Http/HttpRequest";
import YearSelector, {YEAR_TYPES} from "../../Common/Component/Select/YearSelector";
import VersaoSelector, {MapPtcVersao} from "./Component/VersaoSelector";
import DropdownItem from "react-bootstrap/DropdownItem";
import {AgendamentoPTCListSchema} from "../AgendamentosPTC/AgendamentoPTCListSchema";
import {ReactTable} from "../ListPage/Components/Table/ReactTable";
import {saveLocalDataForm} from "../FormPage/FormDataLocalService";
import {Card, Col, DropdownButton, Row} from "react-bootstrap";
import AprovarModal from "../../Common/Component/Modal/AprovarModal";
import Notify from "../../Common/Notify/Notify";

const ptcAtivo = "bg-success";
const ptcInativo = "bg-danger";
const ptcRascunho = "bg-warning";
const estiloPtcStatus = "d-flex float-right p-1 rounded align-content-center border px-2 mt-1";

function PTCPaginaInicial() {

  const navigate = useNavigate();
  const [ptc, setPTC] = useState(null);
  const [ano, setAno] = useState(YEAR_TYPES.find(x => x.id == new Date().getFullYear()));
  const [listSchema, setListSchema] = useState(null);
  const [mostrarModal, setModal] = useState(false);
  const approveEndpoint = 'aprovar-ptc';

  const esconderModal = () => setModal(false);
  const aparecerModal = () => setModal(true);

  async function ptcData() {
    const data = await HttpRequest.get(`ptc?ano=${ano.id}`);
    setPTC(MapPtcVersao(data.content[0]));
  }

  useEffect(() => {
    ptcData();
  }, [])

  useEffect(() => {
    if (ptc) {
      const schema = AgendamentoPTCListSchema(ptc);
      setListSchema(schema)
    } else {
      setListSchema(null)
    }
  }, [ptc])

  useEffect(() => {
    setPTC(null);
  }, [ano])

  const redirectPtcForm = () => {
    navigate("/ptc-menu/ptc/form");
  }

  const aprovarPTC = () => {
    const url = `${approveEndpoint}`
    HttpRequest.post(url, {ptc: {id: ptc.id}}).then(x => {
      Notify.success('PTC Aprovado!');
      navigate(0);
    }).catch(e => {
      Notify.error('Falha ao aprovar PTC', e);
    });
  }

  const redirectClonePtcForm = () => {
    saveLocalDataForm('ptc', ptc)
    navigate(`/ptc-menu/ptc-clone/form`);
  }

  const redirectAgendamentoPtcForm = (ptc) => {
    saveLocalDataForm('ptc', ptc)
    navigate(`/ptc-menu/agendamentos-ptc/form`);
  }

  return <Page title="Programa Trimestral de Carga - PTC">
    <Card>
      <Card.Body className="my-1 mx-1 pt-2">
        <Row className="sm">
          <Col className="ml-3 col-3 pb-0">
            <YearSelector value={ano} setValue={setAno}></YearSelector>
          </Col>
          <Col className="col-3">
            <VersaoSelector setValue={setPTC} value={ptc} ano={ano}></VersaoSelector>
          </Col>
          <Col>
            <DropdownButton className="dropdown mt-4 pt-2 info" title={<i className="fas fa-paperclip"></i>}>
              <DropdownItem onClick={redirectPtcForm}>
                <i className="fas fa-plus"/>&nbsp; Novo PTC
              </DropdownItem>
              <DropdownItem onClick={redirectClonePtcForm}>
                <i className="far fa-copy"/>&nbsp; Clonar PTC
              </DropdownItem>
              {ptc?.status === 'RASCUNHO' &&
                <DropdownItem onClick={() => redirectAgendamentoPtcForm(ptc)}>
                  <i className="fas fa-calendar-plus"/>&nbsp; Adicionar Agendamento
                </DropdownItem>
              }
              {ptc?.status === 'RASCUNHO' &&
                <>
                  <DropdownItem onClick={aparecerModal}>
                    <i className="fas fa-check"/>&nbsp; Aprovar PTC
                  </DropdownItem>
                  {mostrarModal &&
                    <AprovarModal show={mostrarModal} onClose={esconderModal} aprovarPTC={aprovarPTC}/>
                  }
                </>
              }
            </DropdownButton>
          </Col>
          <Col>
            <div className={estiloPtcStatus}>
              <span
                className={`dot ${ptc?.status === "ATIVA" ? ptcAtivo : ptc?.status === "INATIVA" ? ptcInativo : ptcRascunho} d-flex align-self-center`}/>
              <span>&nbsp;
                {ptc ? ` ${ptc?.status} - ${ptc?.ano} - Versão: ${ptc?.versao}` : ''}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          {ptc?.descricao &&
            <Card style={{width: "100%"}}>
              <Card.Body>
                <Card.Title>Descrição</Card.Title>
                <Card.Text>
                  {`${ptc?.descricao}`}
                </Card.Text>
              </Card.Body>
            </Card>
          }
        </Row>
        <Row>
          {listSchema && <ReactTable tableSchema={listSchema}/>}
        </Row>
      </Card.Body>
    </Card>
  </Page>

}

export default PTCPaginaInicial;
