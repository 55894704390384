import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const NAVIO_STATUS = [
  {id: true, label: 'ATIVO'},
  {id: false, label: 'INATIVO'},
];

const PendenteSelector = ({
  required = false,
  title = "Status",
  setValue = NAVIO_STATUS,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      placeholder={"Status do Navio"}
      label="label"
      setValue={setValue}
      items={NAVIO_STATUS}
      {...props}
  />
}
export default PendenteSelector;
