import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {FornecedorListSchema} from "./FornecedorListSchema";
import FornecedorFormSchema from "./FornecedorFormSchema";

export const FornecedorModule = () => {


  const FORNECEDOR_LIST = MenuItem({
    name: 'Fornecedor',
    path: 'fornecedor',
    icon: 'fa fa-user',
    canCreateRoute: false
  });

  addListSchema('fornecedor', FornecedorListSchema);
  addFormSchema('fornecedor', FornecedorFormSchema);

  CADASTRO_MENU.children.push(FORNECEDOR_LIST);

  return [];

}
