import React from "react";
import {TableButton} from "./TableButton";

export const EditButton = ({row, editEndpoint}) => {
  return (<TableButton href={editEndpoint != null ? `../${editEndpoint}/${row.original.id}` :'./form/' + row.original.id} className="bg-primary">
        <span className="text-left">
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </span>
        {/*<i className="fas fa-pencil-alt mr-1"></i>*/}
        &nbsp; Editar
      </TableButton>
  )
}
