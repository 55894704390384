import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {CargaNavioPTCListSchema} from "./CargaNavioPTCListSchema";
import CargaNavioPTCFormSchema from "./CargaNavioPTCFormSchema";

export const CargaNavioModule = () => {


  const CARGA_NAVIO_PTC = MenuItem({
    canShowInMenu: false,
    name: 'Carga Navio PTC',
    path: 'carga-agendamento',
    icon: 'fa fa-flag',
    canCreateRoute: false
  });

  addListSchema('carga-agendamento', CargaNavioPTCListSchema);
  addFormSchema('carga-agendamento', CargaNavioPTCFormSchema);

  CADASTRO_MENU.children.push(CARGA_NAVIO_PTC);

  return [];

}
