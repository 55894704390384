import React, {useState} from "react";
import {TableButton} from "./TableButton";
import HttpRequest from "../../../../../Common/Http/HttpRequest";
import Notify from "../../../../../Common/Notify/Notify";
import DeletarModal from "../../../../../Common/Component/Modal/DeletarModal";

export const DeleteButton = ({row, updateTable, deleteEndpoint}) => {
  const [mostrarModal, setShow] = useState(false);
  const esconderModal = () => setShow(false);
  const aparecerModal = () => setShow(true);
  const doDelete = () => {
    const url = `${deleteEndpoint}/${row.original.id}`
    HttpRequest.delete(url).then(x => {
      updateTable();
      Notify.success('Entidade removida!');
      esconderModal();
    }).catch(e => {
      Notify.error('Falha ao remover entidade', e);
    })
  }

  return (
    <>
      <TableButton className="btn-danger bg-danger" onClick={aparecerModal}>
        <span className="text-left">
          <i className="fa fa-trash" aria-hidden="true"></i>
        </span>
        &nbsp; Deletar
      </TableButton>
      {mostrarModal && <DeletarModal show={mostrarModal} onClose={esconderModal} deletar={doDelete}/>}
    </>
  )
}
