import {Accordion, Card} from "react-bootstrap";
import {AccordionToggleComSeta} from "./AccordionToggleComSeta";
import React from "react";
import PilhaMaterial from "./PilhaMaterial";

function EstoqueNavioDetalhado({agendamento, cargas, pilhas}) {

    function alertaSemPilha(materialId, pilhas) {
         if(pilhas?.filter(x => x.material.id === materialId).length <= 0){
             return <span className="text-danger" title="Sem pilhas cadastradas para o material/navio.">
                  <i className="fas fa-exclamation-circle"></i>
             </span>

         }
    }

    return <Card className="pt-3 px-2">
        {cargas &&
                <Accordion defaultActiveKey={cargas && cargas[0]?.id}>
                    {cargas.map(carga =>
                        <Card className="bg-light" key={carga.id}>
                            <Card.Header >
                                <AccordionToggleComSeta className="col-12 border-0 bg-danger h5" variant="link" eventKey={carga.id}>
                                    <span>Material: </span>
                                    {carga.material.nome}&nbsp;&nbsp;
                                    {alertaSemPilha(carga.material.id, pilhas)}
                                </AccordionToggleComSeta>
                            </Card.Header>
                            <Accordion.Collapse eventKey={carga.id}>
                                <Card.Body>
                                    <PilhaMaterial materialId={carga?.material?.id} pilhas={pilhas}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
        }
    </Card>
}

export default EstoqueNavioDetalhado;