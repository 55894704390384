import {Icon} from "../../../Common/Component/Icon/Icon";
import {AccordionContext, Col, Row, useAccordionToggle} from "react-bootstrap";
import {useContext} from "react";

export function AccordionToggleComSeta({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <Row onClick={decoratedOnClick}>
            <Col className="col-lg-11 d-flex justify-content-center">
                {children}
            </Col>
            <Col className="col-lg-1 d-flex justify-content-end">
                {isCurrentEventKey ? <Icon icon="chevron-up"/> : <Icon icon="chevron-down"/>}
            </Col>
        </Row>
    );
}