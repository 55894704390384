import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {formatarTBN} from "../../TBN/Component/TBNFormatter";
import {formatarCodigoPilha} from "./PilhaFormatter";

const getUrl = (filterValue) => {
  return `pilhas?sort=id,DESC&CodigoPilha.Numero=${filterValue}`;
}

const filterItemsFn = (item) => {
  return formatPilha(item);
}

export const formatPilha = (item) => {
  if (item) {
    item.nome = `${formatarCodigoPilha(item.codigoPilha?.numero, item.codigoPilha?.ano)}`;
  }
  return item;
}

const PilhaSelector = ({
  required = false,
  defaultValue,
  title = "Pilha",
  setValue = null,
  ...props
}) => {



  return <BaseSelector
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    filterItems={filterItemsFn}
    {...props}
  />
}
export default PilhaSelector;
