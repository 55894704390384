import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const MapPtc = (item) => {
  if (item) {
    item.nome = `Ano: ${item?.ano} - Versão: ${item?.versao} - Status: ${item?.status} - Descrição: ${item?.descricao}`
  }
  return item;
}

const getUrl = (filterValue) => {
  return `ptc?sort=id,DESC&id=${filterValue}`;
}

const filterItemsFn = (item) => {
  return MapPtc(item);
}

const PTCSelector = ({
  required = false,
  defaultValue,
  title = "PTC",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      filterItems={filterItemsFn}
      {...props}
  />
}
export default PTCSelector;
