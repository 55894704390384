import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const PatioListSchema = () => {

  const endpoint = 'patios';

  return {
    getTitle: () => {
      return 'Lista de Pátios'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Código",
          accessorKey: "codigo"
        },
        {
          header: "Descrição",
          accessorKey: "descricao"
        },
        {
          header: "Quantidade de balizas",
          accessorKey: "quantidadeBalizas"
        },
        {
          header: "Ativo",
          accessorKey: "ativo",
          accessorFn: (row) => row.ativo ? "SIM" : "NÃO"
        },
        {
          header: "Ref. Início",
          accessorKey: "referenciaInicio"
        },
        {
          header: "Ref. Fim",
          accessorKey: "referenciaFim"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


