import TipoMaterialSelector, {TIPO_MATERIAL} from "./Component/TipoMaterialSelector";

const FamiliaDeMaterialFormSchema = () => {
  const baseEndpoint = 'familia-materiais'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.tipo = formData.tipo?.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.tipo = TIPO_MATERIAL.find(x => x.id === formData.tipo);
      return formData;
    },

    getSchema() {
      return {
        title: "Família de Material",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          sigla: {
            type: "string",
            title: "Sigla"
          },
          codigoSap: {
            type: "integer",
            title: "Código SAP"
          },
          codigoSupervia: {
            type: "integer",
            title: "Código Supervia"
          },
          tipo: {
            type: "object",
            title: "Tipo de Material",
            properties: {
              id: {type: "string", title: "Tipo"},
              label: {type: "string", title: "Tipo"}
            }
          }
        }
      }
    },

    getUiSchema: () => {
      return {
        "tipo": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoMaterialSelector disabled={props.disabled}
                                          required={props.required}
                                          title={props.title}
                                          value={props.formData}
                                          setValue={props.onChange}/>
            );
          }
        }
      }
    }
  }
}

export default FamiliaDeMaterialFormSchema;
