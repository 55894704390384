import RoleSelector from "./Component/RoleSelector";

const PermissoesFormSchema = () => {
  const baseEndpoint = 'grupo-permissao'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.roleSistem = formData.roleSistem.nome ? formData.roleSistem.nome : formData.roleSistem;
      return formData;
    },
    formatObjectToGet(formData) {
      formData.roleSistem = { nome : formData.roleSistem };
      return formData;
    },


    getSchema() {
      return {
        title: "Configurar Permissões",
        type: "object",
        properties: {
          roleSistem: {
            type: "object",
            title: "Role",
          },
          grupoAcessoAd: {
            type: "string",
            title: "Grupo AD",
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        roleSistem: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RoleSelector disabled={props.disabled}
                                       required={props.required}
                                       title={props.title}
                                       value={props.formData}
                                       setValue={props.onChange}/>
            );
          },
        },
      }
    }
  }
}

export default PermissoesFormSchema;
