import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = (filterValue) => {
  return `armadores?sort=nome,ASC&nome=${filterValue}`;
}

const ArmadorSelector = ({
  required = false,
  defaultValue,
  title = "Armador",
  setValue = null,
  ...props
}) => {


  return <BaseSelector
    showCreateButton={false}
    defaultValue={defaultValue}
    title={title}
    required={required}
    label="nome"
    getUrl={getUrl}
    setValue={setValue}
    placeholder ={"selecione ou digite para buscar"}
    {...props}
  />
}
export default ArmadorSelector;
