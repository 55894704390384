import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {PatioListSchema} from "./PatioListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";
import PatioFormSchema from "./PatioFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";

export const PatioModule = () => {


  const PATIO_LIST = MenuItem({
    name: 'Pátio',
    path: 'patio',
    icon: 'fa fa-square',
    canCreateRoute: false
  });

  addListSchema('patio', PatioListSchema);
  addFormSchema('patio', PatioFormSchema);

  ARMAZENAMENTO_MENU.children.push(PATIO_LIST);

  return [];

}
