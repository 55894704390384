import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {Roles} from "../Auth/Roles";
import EstoquePaginaInicial from "../Estoque/EstoquePaginaInicial";


export const EstoqueModule = () => {

  const ESTOQUE_PAGE = MenuItem({
    name: 'Estoque',
    path: `estoque-page`,
    icon: 'fa fa-boxes',
    element: <EstoquePaginaInicial/>,
    roles: [Roles.VER_ARMAZENAMENTO, Roles.MANTER_ARMAZENAMENTO]
  });

  ARMAZENAMENTO_MENU.children.push(ESTOQUE_PAGE);

  return [];

}
