import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {FamiliaDeMaterialListSchema} from "./FamiliaDeMaterialListSchema";
import FamiliaDeMaterialFormSchema from "./FamiliaDeMaterialFormSchema";

export const FamiliaMaterialModule = () => {


  const FAMILIA_MATERIAL_LIST = MenuItem({
    name: 'Familia de Material',
    path: 'familiaMaterial',
    icon: 'fa fa-cogs',
    canCreateRoute: false
  });

  addListSchema('familiaMaterial', FamiliaDeMaterialListSchema);
  addFormSchema('familiaMaterial', FamiliaDeMaterialFormSchema);

  CADASTRO_MENU.children.push(FAMILIA_MATERIAL_LIST);

  return [];

}
