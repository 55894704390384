import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {formatarTBN} from "../TBN/Component/TBNFormatter";
import {formatarCodigoPilha} from "./Component/PilhaFormatter";

export const PilhaListSchema = () => {

  const endpoint = 'pilhas';

  return {
    getTitle: () => {
      return 'Lista de Pilhas'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Código",
          accessorKey: "codigoPilha",
          accessorFn: (row) => formatarCodigoPilha(row.codigoPilha.numero, row.codigoPilha.ano)
        },
        {
          header: "Pátio",
          accessorKey: "patio.codigo"
        },
        {
          header: "TBN",
          accessorKey: "tbn",
          accessorFn: (row) => row.tbn ? formatarTBN(row.tbn.numero, row.tbn.ano) : ""
        },
        {
          header: "Baliza Inicial",
          accessorKey: "balizaInicial",

        },
        {
          header: "Baliza Final",
          accessorKey: "balizaFinal"
        },
        {
          header: "Material",
          accessorKey: "material",
          accessorFn: (row) => `${ row.material?.sigla } - ${row.material?.nome}`
        },
        {
          header: "Volume Programado",
          accessorKey: "volumeProgramado"
        },
        {
          header: "Situação",
          accessorKey: "situacao",
          accessorFn: (row) => (row.situacao === "EM_FORMACAO") ? "EM FORMAÇÃO" : row.situacao
        },
        {
          header: "Status",
          accessorKey: "status",
          accessorFn: (row) => row.status ? "ATIVA" : "INATIVA"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


