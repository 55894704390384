import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const TIPO_MATERIAL = [
  {id: "CARVAO_PCI", label: 'CARVÃO PCI'},
  {id: "CARVAO_COQUERIA", label: 'CARVÃO COQUERIA'},
  {id: "CARVAO_TERMICO", label: 'CARVÃO TERMICO'},
  {id: "COQUE", label: 'COQUE'},
  {id: "PETCOKE", label: 'PETCOKE'},
  {id: "MOINHA_COQUE", label: 'MOINHA DE COQUE'},
  {id: "PELOTA_IMPORTACAO", label: 'PELOTA IMPORTACÃO'},
  {id: "PELOTA_CABOTAGEM", label: 'PELOTA CABOTAGEM'},
  {id: "FINOS_PELOTA", label: 'FINOS PELOTA'},
  {id: "OUTROS", label: 'OUTROS'},
];

const TipoMaterialSelector = ({
  required = false,
  title = "Tipo de Material",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      items={TIPO_MATERIAL}
      {...props}
  />
}
export default TipoMaterialSelector;
