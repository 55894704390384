import {MenuItem, MenuService} from "../Common/Component/Menu/MenuService";
import {getFormSchema} from "./FormPage/FormSchemaService";
import {CrudPage} from "./CrudPage/CrudPage";
import {getListSchema} from "./ListPage/ListSchemaService";
import {ArmadorModule} from "./Armador/ArmadorModule";
import {FornecedorModule} from "./Fornecedor/FornecedorModule";
import {MaterialModule} from "./Material/MaterialModule";
import {NavioModule} from "./Navio/NavioModule";
import {PTCModule} from "./PTC/PTCModule";
import {PortoModule} from "./Porto/PortoModule";
import {PlantaModule} from "./Planta/PlantaModule";
import {FamiliaMaterialModule} from "./FamiliaDeMaterial/FamiliDeMaterialModule";
import {AgendamentoPTCModule} from "./AgendamentosPTC/AgendamentoPTCModule";
import {CargaNavioModule} from "./CargaNavioPTC/CargaNavioPTCModule";
import {BandeiraModule} from "./Bandeira/BandeiraModule";
import {PermissoesModule} from "./Permissoes/PermissoesModule";
import {Roles} from "./Auth/Roles";
import {PatioModule} from "./Patio/PatioModule";
import {PilhaModule} from "./Pilha/PilhaModule";
import {MovimentacaoModule} from "./Movimentacao/MovimentacaoModule";
import {EstoqueModule} from "./Estoque/EstoqueModule";
import {AjusteEstoqueModule} from "./AjusteEstoque/AjusteEstoqueModule";
import {VolumeDiarioModule} from "./VolumeDiario/VolumeDiarioModule";

const CADASTRO_FORM = MenuItem({
  name: 'Cadastros Forms',
  path: ':operation',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getFormSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  }
});

const CADASTRO_FORM_EDIT = MenuItem({
  name: 'Cadastros Forms Edit',
  path: ':operation/:id',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getFormSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  }
});

const CADASTRO_LIST = MenuItem({
  name: 'Cadastros Lista',
  path: ':entity',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getListSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  },
  children: [
    CADASTRO_FORM,
    CADASTRO_FORM_EDIT]
});

export const CADASTRO_MENU = MenuItem({
  path: 'cadastros',
  icon: 'fa fa-book',
  canShowInMenu: true,
  name: 'Cadastros',
  roles: [Roles.VER_PTC, Roles.MANTER_PTC],
  children: [
    CADASTRO_LIST,
  ]
});

export const PTC_MENU = MenuItem({
  path: 'ptc-menu',
  icon: 'fas fa-list-ul',
  name: 'PTC',
  roles: [Roles.VER_PTC, Roles.MANTER_PTC],
  children: [
    CADASTRO_LIST,
  ]
});

export const CONFIGURACAO_MENU = MenuItem({
  path: 'configuracao-menu',
  icon: 'fas fa-cog',
  name: 'Configurações',
  roles: [Roles.CONFIGURAR_PERMISSAO],
  children: [
    CADASTRO_LIST,
  ]
});

export const ARMAZENAMENTO_MENU = MenuItem({
  path: 'armazenamento',
  icon: 'fa fa-warehouse',
  canShowInMenu: true,
  name: 'Armazenamento',
  roles: [Roles.VER_ARMAZENAMENTO, Roles.MANTER_ARMAZENAMENTO],
  children: [
    CADASTRO_LIST,
  ]
});

export const LoadMainModules = () => {

  MenuService.addMenu(CADASTRO_MENU);
  MenuService.addMenu(PTC_MENU);
  MenuService.addMenu(ARMAZENAMENTO_MENU);
  MenuService.addMenu(CONFIGURACAO_MENU);

  return [
    ...ArmadorModule(),
    ...AgendamentoPTCModule(),
    ...BandeiraModule(),
    ...FornecedorModule(),
    ...FamiliaMaterialModule(),
    ...MaterialModule(),
    ...NavioModule(),
    ...PTCModule(),
    ...PortoModule(),
    ...PlantaModule(),
    ...CargaNavioModule(),
    ...PermissoesModule(),
    ...PatioModule(),
    ...PilhaModule(),
    ...MovimentacaoModule(),
    ...EstoqueModule(),
    ...AjusteEstoqueModule(),
    ...VolumeDiarioModule(),
  ]
}
