import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {ArmadorListSchema} from "./ArmadorListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";
import ArmadorFormSchema from "./ArmadorFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";

export const ArmadorModule = () => {


  const ARMADOR_LIST = MenuItem({
    name: 'Armador',
    path: 'armador',
    icon: 'fa fa-user',
    canCreateRoute: false
  });

  addListSchema('armador', ArmadorListSchema);
  addFormSchema('armador', ArmadorFormSchema);

  CADASTRO_MENU.children.push(ARMADOR_LIST);

  return [];

}
