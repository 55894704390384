import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {useCallback, useState} from "react";

const MaterialByTbnSelector = ({
  required = false,
  defaultValue,
  title = "Material",
  setValue = null,
  tbn,
  ...props
}) => {

  const [oldTbn, setOldTbn] = useState(null);

  const getUrl = useCallback(() => {
    if (oldTbn) {
      setValue && setValue(null);
    }
    setOldTbn(tbn)
    return `v1/combobox/materiais/tbn/${tbn?.id}`;
  }, [tbn?.id])

  if (!tbn) {
    return <></>
  }

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />
}
export default MaterialByTbnSelector;
