import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const TIPO_ESTOQUE = [
  {id: 'NAVIO', label: 'Navio'},
  {id: 'MATERIAL', label: 'Material'},
  {id: 'PILHA', label: 'Pilha'},
];

const TipoEstoqueSelector = ({
  required = false,
  title = "Mostrar estoque por:",
  setValue = TIPO_ESTOQUE,
  ...props
}) => {

  return <BaseSelector
    showCreateButton={false}
    title={title}
    required={required}
    label="label"
    setValue={setValue}
    items={TIPO_ESTOQUE}
    {...props}
  />
}
export default TipoEstoqueSelector;
