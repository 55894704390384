import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const CATEGORIA_NAVIO = [
  {id: 'CAPEMAX', label: 'CAPEMAX'},
  {id: 'CAPESIZE', label: 'CAPESIZE'},
  {id: 'HANDSIZE', label: 'HANDSIZE'},
  {id: 'PANAMAX', label: 'PANAMAX'},
];

const CategoriaSelector = ({
  required = false,
  title = "Categoria",
  setValue = CATEGORIA_NAVIO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      items={CATEGORIA_NAVIO}
      {...props}
  />
}
export default CategoriaSelector;
