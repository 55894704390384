import PilhaSelector, {formatPilha} from "./Component/PilhaSelector";
import TipoMovimentacaoSelector, {TIPO_MOVIMENTACAO} from "./Component/TipoMovimentacaoSelector";

const MovimentacaoFormSchema = () => {
  const baseEndpoint = 'movimentacoes'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.tipoMovimentacao = formData.tipoMovimentacao.id;
      formData.meioMovimentacao = "OPERACAO";
      return formData;
    },

    formatObjectToGet(formData) {
      formData.tipoMovimentacao = TIPO_MOVIMENTACAO.find(x => x.id === formData.tipoMovimentacao);
      return formData;
    },

    getSchema() {
      return {
        title: "Movimentações",
        type: "object",
        properties: {
          pilha: {
            type: "object",
            title: "Pilha",
          },
          tipoMovimentacao: {
            type: "object",
            title: "Tipo de movimentação",
          },
          data: {
            type: "string",
            format: "date-time",
            title: "Data da movimentação",
          },
          volume: {
            type: "number",
            title: "Volume movimentado (t)",
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        pilha: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PilhaSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={formatPilha(props.formData)}
                                   setValue={props.onChange}/>
            );
          }
        },
        tipoMovimentacao: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TipoMovimentacaoSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default MovimentacaoFormSchema;
