import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const TIPO = [
  {id: true, label: 'EMBARQUE'},
  {id: false, label: 'DESEMBARQUE'},
];

const EmbarqueTipoSelector = ({
  required = false,
  title = "Tipo de Operação",
  setValue = TIPO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      placeholder={"Selecione para Buscar"}
      items={TIPO}
      {...props}
  />
}
export default EmbarqueTipoSelector;
