import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";

export const ArmadorListSchema = () => {

  const endpoint = 'armadores';

  return {
    getTitle: () => {
      return 'Lista de Armador'
    },
    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}


