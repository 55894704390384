import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {VolumeDiarioListSchema} from "./VolumeDiarioListSchema";

export const VolumeDiarioModule = () => {


  const VOLUME_DIARIO_LIST = MenuItem({
    name: 'Volume diário',
    path: 'volume-diario',
    icon: 'fa fa-calendar-alt',
    canCreateRoute: false
  });

  addListSchema('volume-diario', VolumeDiarioListSchema);

  ARMAZENAMENTO_MENU.children.push(VOLUME_DIARIO_LIST);

  return [];

}
