import React, {useState} from "react";
import HttpRequest from "../../../Common/Http/HttpRequest";
import Notify from "../../../Common/Notify/Notify";
import {TableButton} from "../../ListPage/Components/Table/Buttons/TableButton";
import AprovarModal from "../../../Common/Component/Modal/AprovarModal";

export const AprovarPTCButton = ({row, updateTable, disabled}) => {

  const approveEndpoint = 'aprovar-ptc';

  const [mostrarModal, setShow] = useState(false);
  const esconderModal = () => setShow(false);
  const aparecerModal = () => setShow(true);

  const approveEntity = () => {
    const url = `${approveEndpoint}`
    HttpRequest.post(url, {
      ptc: {id: row.original.id}
    }).then(x => {
      updateTable();
      Notify.success('PTC Aprovado!');
    }).catch(e => {
      Notify.error('Falha ao aprovar PTC', e);
    })
  }

  return (
    <>
      <TableButton className="btn-success bg-success" onClick={aparecerModal}>
        <span className="text-left">
          <i className="fas fa-check" aria-hidden="true"></i>
        </span>
        &nbsp; Aprovar
      </TableButton>

      {mostrarModal && <AprovarModal show={mostrarModal} onClose={esconderModal} aprovarPTC={approveEntity}/>}
    </>
  )
}

export default AprovarPTCButton;
