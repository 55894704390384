import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {formatarTBN} from "../TBN/Component/TBNFormatter";
import {dayMonthYearAtTime} from "../../Common/Utils/DateFormat";
import CargasDoAgendamentoComponent from "./Component/CargasDoAgendamentoComponent";
import NavioSelector from "../Navio/Component/NavioSelector";
import ArmadorSelector from "../Armador/Component/ArmadorSelector";
import {DateColumnFilter} from "../ListPage/Components/Table/Filter/DefaultColumnFilter";

export const AgendamentoPTCListSchema = (ptc) => {
  let endpoint = 'agendamentos-ptc';
  let createEndpoint = 'carga-agendamento/form'

  if(ptc){
    endpoint = `agendamentos-ptc?PTC.Id=${ptc?.id}`;
  }

  let endpointBase = 'agendamentos-ptc';

  return {
    renderRowSubComponent: ({row: {original}}) => {
      return <CargasDoAgendamentoComponent id={original.id} ptc={ptc} />
    },

    getTitle: () => {
      return 'Lista de Agendamentos do PTC'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      if (ptc.status === "RASCUNHO"){
        return [
          {
            header: "TBN",
            accessorFn: (row) => row.tbn ? formatarTBN(row.tbn.numero, row.tbn.ano) : ""
          },
          {
            id:"Navio.Id",
            header: "Navio",
            accessorKey: "navio.nome",
            customFilterSelectorElement:NavioSelector,
            accessorFn: (row) => row.navio?.nome
          },
          {
            id: "Armador.Id",
            header: "Armador",
            accessorKey: "armador",
            customFilterSelectorElement:ArmadorSelector,
            accessorFn: (row) => row.armador?.nome
          },
          {
            header: "ETA D",
            accessorKey: "etaD",
            accessorFn: (row) => dayMonthYearAtTime(row.etaD)
          },
          {
            header: "Data Inicio de Desembarque",
            accessorKey: "inicioDesembarque",
            accessorFn: (row) => dayMonthYearAtTime(row.inicioDesembarque)
          },
          {
            header: "Data Fim do Desembarque",
            accessorKey: "fimDesembarque",
            accessorFn: (row) => dayMonthYearAtTime(row.fimDesembarque)
          },
          TableButtonOptions({
            deleteEndpoint: endpointBase,
            editEndpoint: `${endpointBase}/form`,
            showCreateCargaButton: true,
            createEndpoint: createEndpoint,
          }),
        ]
      }
      return [
        {
          header: "TBN",
          accessorKey: "tbn",
          accessorFn: (row) => row.tbn ? formatarTBN(row.tbn.numero, row.tbn.ano) : ""
        },
        {
          header: "Navio",
          accessorKey: "navio",
          accessorFn: (row) => row.navio?.nome
        },
        {
          header: "Armador",
          accessorKey: "armador",
          accessorFn: (row) => row.armador?.nome
        },
        {
          header: "ETA D",
          accessorKey: "etaD",
          accessorFn: (row) => dayMonthYearAtTime(row.etaD)
        },
        {
          header: "Data Inicio de Desembarque",
          accessorKey: "inicioDesembarque",
          accessorFn: (row) => dayMonthYearAtTime(row.inicioDesembarque)
        },
        {
          header: "Data Fim do Desembarque",
          accessorKey: "fimDesembarque",
          accessorFn: (row) => dayMonthYearAtTime(row.fimDesembarque)
        },
      ]
    },

  }
}


