import React, {useEffect, useState} from "react";
import {Page} from "../../Common/Component/Page/Page";
import {Card, Col, Row} from "react-bootstrap";
import TipoEstoqueSelector from "./Component/TipoEstoqueSelector";
import AgendamentoSelector from "./Component/AgendamentoSelector";
import EstoqueNavioDetalhado from "./Component/EstoqueNavioDetalhado";
import HttpRequest from "../../Common/Http/HttpRequest";
import MaterialSelector from "../Material/Component/MaterialSelector";
import PilhaSelector from "../Pilha/Component/PilhaSelector";
import EstoqueMaterialDetalhado from "./Component/EstoqueMaterialDetalhado";
import EstoquePilhaDetalhado from "./Component/EstoquePilhaDetalhado";

function EstoquePaginaInicial() {

    const [tipoFiltro, setTipoFiltro] = useState(null);
    const [agendamento, setAgendamento] = useState(null);
    const [cargas, setCargas] = useState(null);
    const [pilhas, setPilhas] = useState(null);
    const [material, setMaterial] = useState(null);
    const [pilha, setPilha] = useState(null);

    async function getCargas(agendamento) {
        if (agendamento?.id !== undefined) {
            const data = await HttpRequest.get(`carga-agendamento?AgendamentoPTC.Id=${agendamento?.id}`);
            setCargas(data.content);
        }
    }

    async function getPilhas(agendamento) {
        if (agendamento?.id !== undefined) {
            const data = await HttpRequest.get(`pilhas?Tbn.Id=${agendamento?.tbn.id}`);
            setPilhas(data.content);
        }
    }

    async function getPilhasPorMaterial(material) {
        if (material?.id !== undefined) {
            const data = await HttpRequest.get(`pilhas?Material.Id=${material.id}`);
            setPilhas(data.content);
        }
    }

    useEffect(() => {
        setPilhas(null);
        setAgendamento(null);
        setCargas(null);
        setPilha(null);
        setMaterial(null);
    }, [tipoFiltro])

    useEffect(() => {
        setCargas(null);
        setPilhas(null);
        getCargas(agendamento);
        getPilhas(agendamento);
    }, [agendamento])

    useEffect(() => {
        setPilhas(null);
        getPilhasPorMaterial(material);
    }, [material])

    useEffect(() => {
        setMaterial(null);
    }, [pilha])

    return <Page title="Estoque">
        <Card>
            <Card.Body className="my-1 mx-1 pt-2">
                <Row className="sm">
                    <Col className="ml-3 col-3 pb-0">
                        <TipoEstoqueSelector value={tipoFiltro} setValue={setTipoFiltro}></TipoEstoqueSelector>
                    </Col>
                    {tipoFiltro && tipoFiltro.id === "NAVIO" &&
                        <Col className="col-3">
                            <AgendamentoSelector value={agendamento} setValue={setAgendamento}></AgendamentoSelector>
                        </Col>
                    }
                    {tipoFiltro && tipoFiltro.id === "MATERIAL" &&
                        <Col className="col-3">
                            <MaterialSelector value={material} setValue={setMaterial}></MaterialSelector>
                        </Col>
                    }
                    {tipoFiltro && tipoFiltro.id === "PILHA" &&
                        <Col className="col-3">
                            <PilhaSelector value={pilha} setValue={setPilha}></PilhaSelector>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
        {tipoFiltro && tipoFiltro.id === "NAVIO" && agendamento && cargas &&
            <EstoqueNavioDetalhado agendamento={agendamento} cargas={cargas} pilhas={pilhas}/>}
        {tipoFiltro && tipoFiltro.id === "MATERIAL" && material && pilhas &&
            <EstoqueMaterialDetalhado material={material} pilhas={pilhas} />}
        {tipoFiltro && tipoFiltro.id === "PILHA" && pilha &&
            <EstoquePilhaDetalhado pilha={pilha}></EstoquePilhaDetalhado>}
    </Page>
}

export default EstoquePaginaInicial;
