import {Button, Modal} from "react-bootstrap";
import React from "react";

const DeletarModal = ({show,onClose, deletar}) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Deletar</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você realmente deseja Deletar?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
        <Button variant="danger" onClick={deletar}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletarModal;
