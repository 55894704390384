import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const PENDENTE = [
  {id: true, label: 'SIM'},
  {id: false, label: 'NÃO'},
];

const PendenteSelector = ({
  required = false,
  title = "Pendente",
  setValue = PENDENTE,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      placeholder={"Navio Pendente?"}
      label="label"
      setValue={setValue}
      items={PENDENTE}
      {...props}
  />
}
export default PendenteSelector;
