import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {CADASTRO_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {MaterialListSchema} from "./MaterialListSchema";
import MaterialFormSchema from "./MaterialFormSchema";

export const MaterialModule = () => {


  const MATERIAL_LIST = MenuItem({
    name: 'Material',
    path: 'material',
    icon: 'fa fa-cog',
    canCreateRoute: false
  });

  addListSchema('material', MaterialListSchema);
  addFormSchema('material', MaterialFormSchema);

  CADASTRO_MENU.children.push(MATERIAL_LIST);

  return [];

}
