import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = (filterValue) => {
  return `fornecedores?sort=nome,ASC&nome=${filterValue}`;//TODO SETAR URl
}

const FornecedorSelector = ({
  required = false,
  defaultValue,
  title = "Fornecedor",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />
}
export default FornecedorSelector;
