import React from "react";
import {TableButton} from "../../ListPage/Components/Table/Buttons/TableButton";
import {saveLocalDataForm} from "../../FormPage/FormDataLocalService";

export const CreateCargaButton = ({row, createEndpoint}) => {

  const saveLocal = () => saveLocalDataForm('agendamentos-ptc', row.original);

  return (<TableButton onClick={saveLocal} href={createEndpoint != null ? `../${createEndpoint}`:'./form/' + row.original.id} className="bg-primary">
        <span className="text-left">
          <i className="fas fa-plus" aria-hidden="true"></i>
        </span>
        {/*<i className="fas fa-pencil-alt mr-1"></i>*/}
        &nbsp; Adicionar Carga
      </TableButton>
  )
}
