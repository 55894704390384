import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const STATUS_VERSAO = [
  {id: 'ATIVA', label: 'ATIVA'},
  {id: 'INATIVA', label: 'INATIVA'},
  {id: 'RASCUNHO', label: 'RASCUNHO'},
];

const StatusVersaoSelector = ({
  required = false,
  title = "Status",
  setValue = STATUS_VERSAO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      items={STATUS_VERSAO}
      {...props}
  />
}
export default StatusVersaoSelector;
