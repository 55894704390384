import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {useCallback} from "react";

export const MapPtcVersao = (item) => {
  if (item) {
    item.nome = `Versão: ${item?.versao} - ${item?.ano} - ${item?.status} `
  }
  return item;
}

const filterItemsFn = (item) => {
  return MapPtcVersao(item);
}

const VersaoSelector = ({
  required = false,
  defaultValue,
  setValue = null,
  placeholder = "Versão",
  title = "PTC",
  ano,
  ...props
}) => {

  const getUrl = useCallback(() => {
    return 'ptc?ano=' + ano?.id;
  }, [ano])

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      filterItems={filterItemsFn}
      title={title}
      {...props}
  />
}
export default VersaoSelector;
