import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import BandeiraSelector from "../Bandeira/Component/BandeiraSelector";

export const PortoListSchema = () => {
  const endpoint = 'portos';

  return {
    getTitle: () => {
      return 'Lista de Portos'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },
        {
          header: "Código",
          accessorKey: "codigo"
        },
        {
          id: "Bandeira.Id",
          header: "Bandeira",
          accessorKey: "bandeira.Id",
          customFilterSelectorElement: BandeiraSelector,
          cell: (item) => item.row.original.bandeira?.sigla
        },
        {
          header: "Transit Times",
          accessorKey: "transitTime"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


