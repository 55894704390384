import {Form} from "@rjsf/bootstrap-4";
import validator from '@rjsf/validator-ajv8';
import {Button, Card, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {AppButton} from "../../../Common/Component/Button/AppButton";
import {saveFormData} from "../FormDataService";
import Notify from "../../../Common/Notify/Notify";
import {getLocalDataForm, saveLocalDataForm} from "../FormDataLocalService";

export const CustomForm = ({pageSchema}) => {


  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [entityOnError, setEntityOnError] = useState(null);
  const [resetOnSave, setResetOnSave] = useState(false);

  const [formData, setFormData] = useState(pageSchema.originalFormData);

  const onSave = (eventToSave) => {
    if (resetOnSave) {
      pageSchema.resetOnSave({navigate, pageSchema, eventToSave});
      setResetOnSave(false);
      return;
    }

    if (pageSchema.redirectOnSave) {
      pageSchema.redirectOnSave({navigate, pageSchema, eventToSave});
    } else {
      navigate(-1);
    }
  }


  const onSubmit = (evt) => {
    const formDataInLocalStorage = getLocalDataForm(pageSchema.entity)

    let eventToSave = !!formDataInLocalStorage ? formDataInLocalStorage : evt.formData;

    setLoading(true);
    saveFormData({formData: eventToSave, pageSchema}).then(x => {
      Notify.success('Entidade salva');
      onSave(eventToSave);
      setLoading(false);
    }, err => {
      Notify.error(`Falha ao salvar dados - ${err.message ? err.message : ''}`);
      setLoading(false);
      setEntityOnError(getLocalDataForm(pageSchema.entity));
    })
  }

  const onChange = (e, field) => {
    if (field) {
      const data = e.formData;
      saveLocalDataForm(pageSchema.entity, data);
      setFormData(data);
    }
  }

  const onError = (e) => {
    console.log('on errorrrrr', e)
  }

  return <Form
    disabled={isLoading}
    schema={pageSchema.formSchema}
    validator={validator}
    showErrorList={false}
    uiSchema={pageSchema.uiSchema}
    formData={entityOnError || formData}
    onChange={onChange}
    onSubmit={onSubmit}
    onError={onError}
    formContext={{formData}}
    customValidate={pageSchema.customValidate}
  >
    <Card.Footer style={{margin: '-1.25rem'}}>
      <Row>
        <Col>
          {!(pageSchema.footer?.showCancelButton === false) &&
            <Button size="sm" disabled={isLoading} variant="light" type="reset"
                    onClick={() => navigate(-1)}>Cancelar</Button>}
        </Col>
        <Col className="text-right">
          {(pageSchema.footer?.showAddMoreButton === true) && !pageSchema.dataId &&
            <AppButton className="mr-2" type="submit" onClick={() => setResetOnSave(true)} isLoading={isLoading}>
              <i className="far fa-calendar-plus"></i>
              &nbsp;&nbsp;
              Salvar e Adicionar
            </AppButton>}
          <AppButton type="submit" isLoading={isLoading}>
            <i className="fas fa-save"></i>
            &nbsp;&nbsp;
            Salvar
          </AppButton>
        </Col>
      </Row>
    </Card.Footer>
  </Form>
}
