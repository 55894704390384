export function formatarCodigoPilha(numero, ano) {
  return `${pad(numero, 4)}/${ano}`;
}

function pad(number, length) {
  var str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}