import HttpRequest from "../../../Common/Http/HttpRequest";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import MovimentacoesTable from "./MovimentacoesTable";

function MovimentacaoPilha(pilha) {

    const [movimentacoes, setMovimentacoes] = useState(null);
    const [movimentacoesDeposito, setMovimentacoesDeposito] = useState(null);
    const [movimentacoesRetirada, setMovimentacoesRetirada] = useState(null);

    async function getMovimentacoes(pilha) {
        const data = await HttpRequest.get(`movimentacoes?Pilha.Id=${pilha.pilhaId}`);
        setMovimentacoes(data.content);
    }

    useEffect(() => {
        getMovimentacoes(pilha);
    }, [pilha]);

    useEffect(() => {
        setMovimentacoesDeposito(movimentacoes && movimentacoes.filter(x => x.tipoMovimentacao === "DEPOSITO"))
        setMovimentacoesRetirada(movimentacoes && movimentacoes.filter(x => x.tipoMovimentacao === "RETIRADA"))
    }, [movimentacoes]);

    return <Col>
        <Row>
            {movimentacoes?.length > 0 ? <>
                    <Col>
                        {movimentacoesDeposito &&
                            <MovimentacoesTable titulo={"Entradas"} listaMovimentacoes={movimentacoesDeposito}/>}
                    </Col>
                    <Col>
                        {movimentacoesDeposito &&
                            <MovimentacoesTable titulo={"Saídas"} listaMovimentacoes={movimentacoesRetirada}/>}
                    </Col>
                </>
                :
                <Col>
                    Sem movimentações na pilha.
                </Col>
            }
        </Row>
    </Col>
}

export default MovimentacaoPilha;