import {Card} from "react-bootstrap";
import PilhaMaterial from "./PilhaMaterial";
import React from "react";

function EstoqueMaterialDetalhado({material, pilhas}) {

    return <Card className="pt-3 px-2">
        {material &&
            <Card className="bg-light">
                <Card.Header className="d-flex justify-content-center h4">
                    <span>Material: {material.nome}</span>
                </Card.Header>
                <Card.Body>
                    <PilhaMaterial materialId={material?.id} pilhas={pilhas}/>
                </Card.Body>
            </Card>
        }
    </Card>
}

export default EstoqueMaterialDetalhado;