import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {formatarTBN} from "../../TBN/Component/TBNFormatter";

const getUrl = (filterValue) => {
  return `agendamentos-ptc?PTC.Status=ATIVA&Navio.Nome=${filterValue}`;
}

const filterItemsFn = (item) => {
  return formatPilha(item);
}

export const formatPilha = (item) => {
  if (item) {
    item.nome = `${item.navio?.nome} - TBN: ${formatarTBN(item.tbn.numero, item.tbn.ano)}`;
  }
  return item;
}
const AgendamentoSelector = ({
  required = false,
  defaultValue,
  title = "Navio",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      filterItems={filterItemsFn}
      {...props}
  />
}
export default AgendamentoSelector;
