import {createContext, useEffect, useState} from "react";
import {Loading} from "../Component/Loading/Loading";
import HttpRequest from "../Http/HttpRequest";
import Notify from "../Notify/Notify";

export const UserContext = createContext(null);

export const UserLoad = ({children}) => {

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    HttpRequest.get('v1/auth/me').then((x, e) => {
      setCurrentUser({currentUser: x});
    }).catch(() => {
      Notify.warning('Não autorizado')
      setTimeout(() => {
        // window.location = '/accounts';
        Notify.warning('Não autorizado')
      }, 500)
    });

    /*   setCurrentUser({
         currentUser: {
           company: {id: 'WEB'},
           authorities: []
         }
       })*/
  }, []);

  return <UserContext.Provider value={currentUser}>
    <Loading isLoading={!currentUser}>
      {currentUser && children}
    </Loading>
  </UserContext.Provider>
}

