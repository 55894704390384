const PlantaFormSchema = () => {
  const baseEndpoint = 'plantas'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    getSchema() {
      return {
        title: "Planta",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          codigoPlanta: {
            type: "integer",
            title: "Código"
          },
        }
      }
    }
  }
}

export default PlantaFormSchema;
