// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {library} from '@fortawesome/fontawesome-svg-core'
// import {fas} from '@fortawesome/free-solid-svg-icons'
// import {fa} from '@fortawesome/free-regular-svg-icons'
// import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
// import { faHatCowboy } from '@fortawesome/pro-thin-svg-icons'
// import { faHatChef } from '@fortawesome/sharp-solid-svg-icons'
// import { faPlateUtensils } from '@fortawesome/sharp-regular-svg-icons'

// library.add(fas)

export const Icon = ({icon}) => {
  return <i className={"fa fa-" + icon} aria-hidden="true"></i>

  // return <FontAwesomeIcon icon={icon}/>
}
