import {Button, Modal} from "react-bootstrap";
import React from "react";

const AprovarModal = ({show,onClose, aprovarPTC}) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Aprovar PTC</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você realmente deseja aprovar este PTC?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
        <Button variant="success" onClick={aprovarPTC}>
          Aprovar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AprovarModal;
