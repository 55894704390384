import BaseSelector from "../../../Common/Component/Select/BaseSelector";
import {formatarCodigoPilha} from "./PilhaFormatter";

export const formatPilha = (item) => {
  if (item) {
    console.log("item", item)
    item.nome = formatarCodigoPilha(item.codigoPilha.numero, item.codigoPilha.ano)
  }
  return item;
}

const getUrl = (filterValue) => {
  return `pilhas?sort=id,DESC&id=${filterValue}`;
}

const filterItemsFn = (item) => {
  return formatPilha(item);
}

const PilhaSelector = ({
  required = false,
  defaultValue,
  title = "Pilha",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="nome"
      getUrl={getUrl}
      setValue={setValue}
      filterItems={filterItemsFn}
      {...props}
  />
}
export default PilhaSelector;
