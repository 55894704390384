import PatioSelector, {showSentido} from "../Patio/Component/PatioSelector";
import TBNSelector, {MapTbn} from "../TBN/Component/TBNSelector";
import {formatarCodigoPilha} from "./Component/PilhaFormatter";
import SentidoFormacaoSelector, {SENTIDO_FORMACAO} from "./Component/SentidoFormacaoSelector";
import MaterialByTbnSelector from "../Material/Component/MaterialByTbnSelector";

const PilhaFormSchema = () => {
  const baseEndpoint = 'pilhas'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.sentidoFormacao = formData.sentidoFormacao.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.codigoPilhaFriendly = formData.codigoPilha ? formatarCodigoPilha(formData.codigoPilha.numero, formData.codigoPilha.ano) : " ";
      formData.sentidoFormacao = SENTIDO_FORMACAO.find(x => x.id === formData.sentidoFormacao);
      return formData;
    },

    getSchema() {
      return {
        title: "Pilha",
        type: "object",
        properties: {
          codigoPilha: {
            type: "object",
            title: "Código da pilha",
          },
          codigoPilhaFriendly: {
            type: "string",
            title: "Código da pilha",
            readOnly: true
          },
          situacao: {
            type: "string",
            title: "Situação",
          },
          tbn:{
            type: "object",
            title: "TBN"
          },
          material:{
          },
          patio: {
            type: "object",
            title: "Pátio"
          },
          sentidoFormacao:{
            type: "object",
            title: "Sentido de Formação"
          },
          balizaInicial: {
            type: "integer",
            title: "Baliza incial"
          },
          balizaFinal: {
            type: "integer",
            title: "Baliza final"
          },
          volumeProgramado: {
            type: "number",
            title: "Volume programado (t)",
          },
          previsaoInicioFormacao: {
            type: "string",
            format: "date-time",
            title: "Previsão de início da formação",
          },
          previsaoFimFormacao: {
            type: "string",
            format: "date-time",
            title: "Previsão de fim da formação",
          },
          previsaoInicioRecuperacao: {
            type: "string",
            format: "date-time",
            title: "Previsão de início da recuperação",
          },
          previsaoFimRecuperacao: {
            type: "string",
            format: "date-time",
            title: "Previsão de fim da recuperação",
          },
          inicioFormacao: {
            type: "string",
            format: "date-time",
            title: "Início da formação",
          },
          fimFormacao: {
            type: "string",
            format: "date-time",
            title: "Fim da formação",
          },
          inicioRecuperacao: {
            type: "string",
            format: "date-time",
            title: "Início da recuperação",
          },
          fimRecuperacao: {
            type: "string",
            format: "date-time",
            title: "Fim da recuperação",
          },
          observacao: {
            type: "string",
            title: "Observações",
          },
          status: {
            type: "boolean",
            title: "Ativa",
            default : true
          },
        },
        dependencies:{
          tbn : {
            properties:{
              material: {
                type: "object",
                title: "Material"
              },
            }
          }
        }
      }
    },

    getUiSchema: () => {
      return {
        codigoPilha: {
          "ui:options": {
            label: false
          },
          "ui:widget": "hidden"
        },
        codigoPilhaFriendly: {
          "ui:placeholder": "Gerado automaticamente"
        },
        tbn: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<TBNSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={MapTbn(props.formData)}
                                   setValue={props.onChange}/>
            );
          }
        },
        patio: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<PatioSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   value={showSentido(props.formData)}
                                   setValue={props.onChange}/>
            );
          }
        },
        material: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<MaterialByTbnSelector disabled={props.disabled}
                                   required={props.required}
                                   title={props.title}
                                   tbn={props.formContext.formData.tbn}
                                   defaultValue={props.formData}
                                   value={props.formData}
                                   setValue={props.onChange}/>
            );
          }
        },
        sentidoFormacao: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<SentidoFormacaoSelector disabled={props.disabled}
                                 required={props.required}
                                 title={props.title}
                                 value={props.formData}
                                 setValue={props.onChange}/>
            );
          }
        },
        situacao: {
          "ui:options": {
            placeholder: "PROGRAMADA",
            readonly: true
          }
        },
      }
    }
  }
}

export default PilhaFormSchema;
