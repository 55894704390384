import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {ARMAZENAMENTO_MENU} from "../Modules.routes";
import {PilhaListSchema} from "./PilhaListSchema";
import {addListSchema} from "../ListPage/ListSchemaService";
import PilhaFormSchema from "./PilhaFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";

export const PilhaModule = () => {


  const PILHA_LIST = MenuItem({
    name: 'Pilha',
    path: 'pilha',
    icon: 'fa fa-chart-area',
    canCreateRoute: false
  });

  addListSchema('pilha', PilhaListSchema);
  addFormSchema('pilha', PilhaFormSchema);

  ARMAZENAMENTO_MENU.children.push(PILHA_LIST);

  return [];

}
