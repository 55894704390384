import BaseSelector from "../../../Common/Component/Select/BaseSelector";

export const TIPO = [
  {id: true, label: 'OPERACIONAL'},
  {id: false, label: 'NÃO OPERACIONAL'},
];

const SituacaoSelector = ({
  required = false,
  title = "Situação do Navio",
  setValue = TIPO,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={false}
      title={title}
      required={required}
      label="label"
      placeholder={"Selecione para Buscar"}
      setValue={setValue}
      items={TIPO}
      {...props}
  />
}
export default SituacaoSelector;
