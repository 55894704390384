import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import AprovarPTCButton from "./Component/AprovarPTCButton";
import React from "react";
import {Link} from "react-router-dom";
import yearSelector from "../../Common/Component/Select/YearSelector";
import StatusVersaoSelector from "./Component/StatusVersaoSelector";

export const PTCListSchema = () => {
  const endpoint = 'ptc';
  return {
    getTitle: () => {
      return 'Lista de PTCs'
    },

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        customButtons: <Link to="/ptc-menu/ptc-page" className="btn btn-secondary btn-sm" title="Página PTC">
          <i className="fas fa-th-list" aria-hidden="true"></i>
          &nbsp; Página principal PTC
        </Link>
      }
    },

    getColumns: () => {
      return [
        {
          header: "Ano",
          accessorKey: "ano",
          customFilterSelectorElement: yearSelector
        },
        {
          header: "Versão",
          accessorKey: "versao"
        },
        {
          header: "Status",
          accessorKey: "status",
          customFilterSelectorElement: StatusVersaoSelector,
        },
        {
          header: "Descrição",
          accessorKey: "descricao",
          enableColumnFilter: false
        },

        TableButtonOptions({
          showEditButton: false,
          deleteEndpoint: endpoint,
          showDeleteButton: (row) => row.status !== 'ATIVA' ,
          children: ({row, table}) => {
            return row.original?.status === 'RASCUNHO' && <AprovarPTCButton row={row} updateTable={()=>table.setGlobalFilter(new Date())}/>
          }
        }),
      ]
    },
  }
}


