import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const getUrl = () => `bandeiras?sort=criadoEm%2CDESC&page=0&size=10`;

const BandeiraSelector = ({
  required = false,
  defaultValue,
  value = null,
  title = "Bandeira",
  setValue = null,
  setFilterColumn = null,
  showCreateButton = false,
  ...props
}) => {

  return <BaseSelector
      showCreateButton={showCreateButton}
      pathToCreate={'/cadastros/bandeira/form'}
      placeholder={"Buscar bandeira"}
      defaultValue={defaultValue}
      title={title}
      value={value}
      required={required}
      setFilterColumn={setFilterColumn}
      label="sigla"
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />
}
export default BandeiraSelector;
