import CategoriaSelector, {CATEGORIA_NAVIO} from "./Component/CategoriaSelector";
import BandeiraSelector from "../Bandeira/Component/BandeiraSelector";
import {YearMonthDay} from "../../Common/Utils/DateFormat";

const NavioFormSchema = () => {
  const baseEndpoint = 'navios'
  return {

    getUrlFindById(id) {
      return `${baseEndpoint}/${id}`;
    },

    getSaveUrl(id) {
      return `${baseEndpoint}/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.categoria = formData.categoria.id;
      formData.dataConstrucao = YearMonthDay(formData.dataConstrucao);
      return formData;
    },

    formatObjectToGet(formData) {
      formData.categoria = CATEGORIA_NAVIO.find(x => x.id === formData.categoria);
      formData.dataConstrucao = YearMonthDay(formData.dataConstrucao);
      return formData;
    },

    getSchema() {
      return {
        title: "Cadastro de Navios",
        type: "object",
        properties: {
          nome: {
            type: "string",
            title: "Nome"
          },
          imo: {
            type: "string"
            , title: "IMO"
          },
          bandeira: {
            type: "object",
            title: "Bandeira",
            properties: {
              sigla: {type: "string", title: "Sigla"}
            }
          },
          pendente: {
            type: "boolean",
            title: "Pendente",
            enumNames: ["SIM", "NÃO"]
          },
          dataConstrucao: {
            type: "string",
            format: "date",
            title: "Data de Construção"
          },
          tipo: {
            type: "boolean",
            title: "Tipo",
            enumNames: ["EMBARQUE", "DESEMBARQUE"]
          },
          situacao: {
            type: "boolean",
            title: "Situação",
            enumNames: ["OPERACIONAL", "NÃO OPERACIONAL"]
          },
          status: {
            type: "boolean",
            title: "Status",
            enumNames: ["ATIVO", "INATIVO"]
          },
          dwt: {
            type: "number",
            title: "DWT(T)"
          },
          loa: {
            type: "number",
            title: "LOA(m)"
          },
          calado: {
            type: "number",
            title: "Calado"
          },
          categoria: {
            type: "object", tittle: "Categoria", properties: {
              id: {type: "string", title: "Categoria"},
              label: {type: "string", title: "Categoria"}
            }
          },
          quantidadePoroes: {
            type: "integer",
            title: "Quantidade de porões "
          },
          quantidadeTampas: {
            type: "integer",
            title: "Quantidade de tampas"
          },
          tamanhoTampas: {
            type: "number",
            title: "Tamanho Tampas(m)"
          },
          airDraft: {
            type: "number",
            title: "Air Draft(m)"
          },
          tipoCorda: {
            type: "number",
            title: "Tipo de corda"
          },
          quantidadeMastros: {
            type: "integer",
            title: "Quantidade Mastros"
          },
          tempoTotalDeslasto: {
            type: "number",
            title: "Tempo total de deslastro(h)"
          },
          grossTons: {
            type: "number",
            title: "Gross Tons(t)"
          },
          netTons: {
            type: "number",
            title: "Net Tons(t)"
          },
          observacoes: {
            type: "string",
            title: "Observações"
          },
        }
      }
    },

    getUiSchema: () => {
      return {
        pendente: {
          'ui:widget': 'select',
        },
        tipo: {
          'ui:widget': 'select',
        },
        situacao: {
          'ui:widget': 'select',
        },
        status: {
          'ui:widget': 'select',
        },
        categoria: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<CategoriaSelector disabled={props.disabled}
                                       required={props.required}
                                       title={props.title}
                                       value={props.formData}
                                       setValue={props.onChange}/>
            );
          },
        },
        bandeira: {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<BandeiraSelector disabled={props.disabled}
                                      required={props.required}
                                      showCreateButton={true}
                                      title={props.title}
                                      value={props.formData}
                                      setValue={props.onChange}/>
            );
          }
        },
      }
    }
  }
}

export default NavioFormSchema;
