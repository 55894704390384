import HttpRequest from "../../../Common/Http/HttpRequest";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {dayMonthYear} from "../../../Common/Utils/DateFormat";
import {useNavigate} from "react-router-dom";
import Notify from "../../../Common/Notify/Notify";

function CargasDoAgendamentoComponent({id, ptc}) {
  const navigate = useNavigate();
  const endpoint = 'carga-agendamento';

  const [cargaAgendamentos, setCargaAgendamentos] = useState(null);

  const redirectForm = (i) => {
    navigate(`/cadastros/carga-agendamento/form/${cargaAgendamentos[i]?.id}`);
  }

  const removerCarga = (index) => {
    const removeCarga = cargaAgendamentos.filter((_, i) => i !== index);
    setCargaAgendamentos(removeCarga);
  }
  const doDelete = (i) => {
    const url = `${endpoint}/${cargaAgendamentos[i]?.id}`
    HttpRequest.delete(url).then(x => {
      removerCarga(i);
      Notify.success('Carga removida!');
    }).catch(e => {
      Notify.error('Falha ao remover carga', e);
    })
  }

  async function agendamentoData() {
    const data = await HttpRequest.get(`carga-agendamento?agendamentoPTC.Id=${id}`);
    setCargaAgendamentos(data.content);
  }

  useEffect(() => {
    agendamentoData();
  }, []);

  return (
      cargaAgendamentos && cargaAgendamentos.map((cargaAgendamento, i) =>
          <Col key={i}>
            <Card className="px-2">
              <Row className="bg-light">
                <Col>Data CTR Laydays: {dayMonthYear(cargaAgendamento?.dataCTRLaydays)}</Col>
                <Col>Data de Liberação do Frete: {dayMonthYear(cargaAgendamento?.dataLiberacaoFrete)}</Col>
                <Col>Data CTR Nomeação: {dayMonthYear(cargaAgendamento?.dataCTRNomeacao)}</Col>
                <Col>Material: {cargaAgendamento?.material?.nome + " - " + cargaAgendamento?.material?.sigla}</Col>
                <Col>Familia do Material: {cargaAgendamento?.material?.familiaMaterial?.sigla}</Col>
              </Row>
              <Row>
                <Col>Peso P: {cargaAgendamento?.pesoP}</Col>
                <Col>Peso R: {cargaAgendamento?.pesoR}</Col>
                <Col>Fornecedor: {cargaAgendamento?.fornecedor?.nome}</Col>
                <Col>País de Fornecedor: {cargaAgendamento?.fornecedor?.pais}</Col>
                <Col>Porto: {cargaAgendamento?.porto?.nome}</Col>
              </Row>
              <Row className="bg-light">
                <Col>LAYD: {dayMonthYear(cargaAgendamento?.layd)}</Col>
                <Col>CANC: {dayMonthYear(cargaAgendamento?.canc)}</Col>
                <Col>ETA C: {dayMonthYear(cargaAgendamento?.etaC)}</Col>
                <Col>ETB C: {dayMonthYear(cargaAgendamento?.etbC)}</Col>
                <Col>Data BL: {dayMonthYear(cargaAgendamento?.dataBl)}</Col>

              </Row>
              <Row>
                <Col>TT: {cargaAgendamento?.porto?.transitTime}</Col>
                <Col>Fila: {cargaAgendamento?.fila}</Col>
                <Col>Carga: {cargaAgendamento?.cargaStr}</Col>
                <Col>Fila de Porto de Origem: {cargaAgendamento?.filaPortoOrigem}</Col>
                <Col>Tempo de Carregamento Origem: {cargaAgendamento?.tempoCarregamentoOrigem}</Col>
              </Row>
              <Row className="bg-light">
                <Col>Prazo de Pagamento: {cargaAgendamento?.prazoPagamento}</Col>
                <Col>Frete: {cargaAgendamento?.frete}</Col>
                <Col>CRF: {cargaAgendamento?.crf}</Col>
                <Col>DEM: {cargaAgendamento?.dem}</Col>
                <Col>Planta: {cargaAgendamento?.planta?.nome}</Col>
              </Row>
              <Row className="dflex justify-content-end">
                <Button className="m-1 py-0 px-1" disabled={ptc.status !== "RASCUNHO"} onClick={() => redirectForm(i)}><i
                    className="fas fa-pencil-alt"></i></Button>
                <Button className="m-1 py-0 px-1 bg-danger border-danger" disabled={ptc.status !== "RASCUNHO"} onClick={() => doDelete(i)}><i
                    className="fas fa-trash-alt"></i></Button>
              </Row>
            </Card>
          </Col>
      )
  )
}

export default CargasDoAgendamentoComponent;
