import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {TIPO_MATERIAL} from "./Component/TipoMaterialSelector";

export const FamiliaDeMaterialListSchema = () => {
  const endpoint = 'familia-materiais';

  return {
    getTitle: () => {
      return 'Lista de Familias de Materiais'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "nome"
        },

        {
          header: "Sigla",
          accessorKey: "sigla"
        },
        {
          header: "Código SAP",
          accessorKey: "codigoSap"
        },
        {
          header: "Código Supervia",
          accessorKey: "codigoSupervia",
        },
        {
          header: "Tipo",
          accessorKey: "tipo",
          enableColumnFilter: false,
          cell: (item) => TIPO_MATERIAL.find(x => x.id === item.row.original.tipo)?.label
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
        }),
      ]
    },

  }
}


